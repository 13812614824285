import React from 'react';
import { Image, Spinner } from 'react-bootstrap';
import Calendar from '../../components/Calendar';
import ShareButton from '../../components/UI/ShareButton';
import usePrint from '../hooks/usePrint';
import parse from 'html-react-parser';

const PostPageLayout = ({ props }) => {
    const { printRef, handlePrint } = usePrint();

    if (!props) {
        return (
            <div className="d-flex align-items-center justify-content-center py-5">
                <Spinner animation="grow" />
            </div>
        );
    }

    return (
        <div className="d-flex flex-xl-row flex-column justify-content-center">
            <div className="d-flex flex-column col-xl-8 col-12 px-xl-4 px-2" ref={printRef}>
                {/* Заголовок */}
                {props.title && (
                    <div className="py-4 block-title text-center">
                        <span className="text-primary">{props.title}</span>
                    </div>
                )}

                {/* Основной контент */}
                <div className="d-flex flex-column align-items-center justify-content-center">
                    {props.img && (
                        <Image
                            src={`${process.env.REACT_APP_API_URL}${props.img}`}
                            style={{
                                maxHeight: '50vh',
                                width: 'auto',
                                maxWidth: '100%',
                                aspectRatio: 1,
                                objectFit: 'contain',
                            }}
                        />
                    )}
                    {props.description && <span className="text-wrap py-3">{parse(props.description)}</span>}
                </div>

                {/* Подписи, дата, автор */}
                <div className="d-flex flex-xl-row flex-column align-items-center justify-content-between w-100 px-xl-5">
                    {props.author && (
                        <span className="border border-primary rounded text-primary my-sm-2 p-2 fw-bold">
                            Autor: {props.author}
                        </span>
                    )}
                    {props.date && (
                        <span className="border border-primary rounded text-primary p-2 fw-bold">{props.date}</span>
                    )}
                    <div className="d-flex flex-row align-items-center justify-content-end">
                        <i
                            role="button"
                            onClick={handlePrint}
                            className="bi bi-printer text-primary icon-3"
                            style={{ fontWeight: '600 !important', WebkitTextStroke: 1 }}
                        />
                        <ShareButton />
                    </div>
                </div>
            </div>
            <Calendar />
        </div>
    );
};

export default PostPageLayout;
