import React, { useContext, useState, useEffect, useRef } from 'react';
import Calendar from '../components/Calendar';
import { Image } from 'react-bootstrap';
import { fetchOneMayorMessage } from '../http/activitiesAPI';
import { Context } from '..';
import { Spinner } from 'react-bootstrap';
import ShareButton from '../components/UI/ShareButton';

const PresidentsMessage = () => {
    const { activities } = useContext(Context);
    const [loading, setLoading] = useState(true);

    const printRef = useRef(null);
    const handlePrint = () => {
        const originalBodyContent = document.body.innerHTML;
        document.body.innerHTML = printRef.current.outerHTML;
        window.print();

        onafterprint = (e) => {
            console.log(e);

            document.body.innerHTML = originalBodyContent;
            window.location.reload();
        };
    };

    useEffect(() => {
        fetchOneMayorMessage(1)
            .then((data) => {
                activities.setMayorMessage(data);
            })
            .finally(() => setLoading(false));
    }, [activities]);
    if (loading) {
        return (
            <div className="d-flex align-items-center justify-content-center py-5">
                <Spinner animation={'grow'} />
            </div>
        );
    }
    return (
        <div>
            <div className="d-flex flex-xl-row flex-column justify-content-center">
                {activities.mayorMessage ? (
                    <div className="d-flex flex-column col-xl-8 col-12 px-xl-4 px-2" ref={printRef}>
                        <div className="py-4 block-title text-center">
                            <span className="text-primary">{activities.mayorMessage.title}</span>
                        </div>
                        <div className="d-flex flex-column align-items-center justify-content-center">
                            {/* <Image
								src={process.env.REACT_APP_API_URL + activities.mayorMessage.img}
								className='rounded'
							/> */}
                            {activities.mayorMessage.img ? (
                                // <Image
                                // 	className='rounded w-100 '
                                // 	style={{
                                // 		background: `url(${
                                // 			process.env.REACT_APP_API_URL + activities.mayorMessage.img
                                // 		})  center center/cover no-repeat`,
                                // 		height: "auto",
                                // 		aspectRatio: 3 / 2,
                                // 		backgroundSize: "cover !important",
                                // 	}}
                                // />
                                <Image
                                    src={process.env.REACT_APP_API_URL + activities.mayorMessage.img}
                                    style={{
                                        maxHeight: '50vh',
                                        width: 'auto',
                                        maxWidth: '100%',
                                        aspectRatio: 1,
                                        objectFit: 'contain',
                                    }}
                                />
                            ) : null}
                            <span className="text-wrap py-3">{activities.mayorMessage.description}</span>
                        </div>
                        <div className="d-flex flex-xl-row flex-column align-items-center justify-content-between w-100 px-xl-5">
                            {activities.mayorMessage.author ? (
                                <span className="border border-primary rounded text-primary my-sm-2 p-2 fw-bold">
                                    Autor: {activities.mayorMessage.author}
                                </span>
                            ) : null}
                            {activities.mayorMessage.date ? (
                                <span className="border border-primary rounded text-primary p-2 fw-bold">
                                    {activities.mayorMessage.date}
                                </span>
                            ) : null}
                            <div className="d-flex flex-row align-items-center justify-content-end">
                                <i
                                    role="button"
                                    onClick={() => handlePrint()}
                                    className="bi bi-printer text-primary icon-3"
                                    style={{
                                        fontWeight: '600 !important',
                                        WebkitTextStroke: 1,
                                    }}
                                />
                                <ShareButton />
                            </div>
                        </div>
                    </div>
                ) : null}
                <Calendar />
            </div>
        </div>
    );
};

export default PresidentsMessage;
