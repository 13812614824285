import { useContext, useState, useEffect } from 'react';
import { Spinner, Card, Accordion, Form, useAccordionButton } from 'react-bootstrap';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { observer } from 'mobx-react-lite';
import { Context } from '../../index';
import AddLastNewsModal from './Modals/AddLastNewsModal';
import { fetchLastNews, removeOneLastNews, updateOneLastNews } from '../../http/NewsAPI';
import { LAST_NEWS_ROUTE } from '../../utils/consts';
import parse from 'html-react-parser';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const LastNewsAdmin = observer(() => {
    const { lastNews } = useContext(Context);
    const [show, setShow] = useState(false);

    const [title, setTitle] = useState('');
    const [article, setArticle] = useState('');

    const [loading, setLoading] = useState(true);
    useEffect(() => {
        fetchLastNews()
            .then((data) => {
                lastNews.setLastNews(data);
            })
            .finally(() => setLoading(false));
    }, []);

    const modules = {
        toolbar: [
            [{ size: ['small', false, 'large', 'huge'] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }, { align: [] }],
            ['link', 'image'],
            [
                {
                    color: [
                        '#000000',
                        '#e60000',
                        '#ff9900',
                        '#ffff00',
                        '#008a00',
                        '#0066cc',
                        '#9933ff',
                        '#ffffff',
                        '#facccc',
                        '#ffebcc',
                        '#ffffcc',
                        '#cce8cc',
                        '#cce0f5',
                        '#ebd6ff',
                        '#bbbbbb',
                        '#f06666',
                        '#ffc266',
                        '#ffff66',
                        '#66b966',
                        '#66a3e0',
                        '#c285ff',
                        '#888888',
                        '#a10000',
                        '#b26b00',
                        '#b2b200',
                        '#006100',
                        '#0047b2',
                        '#6b24b2',
                        '#444444',
                        '#5c0000',
                        '#663d00',
                        '#666600',
                        '#003700',
                        '#002966',
                        '#3d1466',
                        'custom-color',
                    ],
                },
            ],
        ],
    };

    const formats = [
        'header',
        'height',
        'bold',
        'italic',
        'underline',
        'strike',
        'blockquote',
        'list',
        'color',
        'bullet',
        'indent',
        'link',
        'image',
        'align',
        'size',
    ];

    const CustomToggle = ({ children, eventKey, item }) => {
        const decoratedOnClick = useAccordionButton(eventKey, () => {
            setTitle(item.title);
            setArticle(item.article);
        });

        return (
            <button
                className="btn btn-primary d-flex align-items-center mb-xl-1 mx-xl-0 mx-2"
                onClick={decoratedOnClick}
            >
                {children}
            </button>
        );
    };

    const removeLastNews = (item) => {
        removeOneLastNews(item.id).then(() => window.location.reload());
    };

    const editLastNews = (data) => {
        const id = data.id;
        const formData = new FormData();
        formData.append('title', title);
        formData.append('article', article);
        updateOneLastNews(formData, id).then(() => window.location.reload());
    };

    if (loading) {
        return (
            <div className="d-flex align-items-center justify-content-center py-5">
                <Spinner animation={'grow'} />
            </div>
        );
    }
    return (
        <div className="d-flex flex-column bg-white r-corner-radius w-100">
            <div className="d-flex justify-content-between align-items-center font-2 fw-bold text-primary shadow-sm ps-3">
                <span>Știri de Ultimă Oră</span>
                <button
                    className="btn btn-primary r-u-corner-radius"
                    style={{ height: 70, width: 70 }}
                    onClick={() => setShow(true)}
                >
                    <i className="bi bi-plus-circle icon-2" />
                </button>
            </div>
            <div className="d-flex flex-column px-3" style={{ height: '100%', overflow: 'auto' }}>
                <Accordion className="d-flex flex-column px-3">
                    {lastNews.lastNews.count > 0
                        ? lastNews.lastNews.rows.map((item, idx) => (
                              <div key={idx} className="shadow-sm border-0 my-2 px-4 rounded">
                                  <Card.Body className="d-flex flex-column">
                                      <div className="d-flex flex-xl-row flex-column align-items-center justify-content-between p-3">
                                          <div>
                                              <a
                                                  href={LAST_NEWS_ROUTE + '/' + item.id}
                                                  className="text-decoration-none text-dark"
                                              >
                                                  <Card.Title className="text-primary truncate">
                                                      {item.title}
                                                  </Card.Title>
                                              </a>
                                              <Card.Text className="truncate pe-0 pe-xl-2">
                                                  {parse(item.article)}
                                              </Card.Text>
                                              <div className="d-flex justify-content-around pt-3">
                                                  <span className="border border-primary rounded text-primary py-1 px-2">
                                                      {item.date}
                                                  </span>
                                              </div>
                                          </div>
                                          <div className="d-flex flex-xl-column flex-row justify-content-around py-xl-0 py-3">
                                              <CustomToggle
                                                  eventKey={idx}
                                                  item={item}
                                                  onClick={() => {
                                                      useAccordionButton({ idx });
                                                  }}
                                              >
                                                  <i className="bi bi-pencil-square icon-2 pe-2" />
                                                  <span>Edit</span>
                                              </CustomToggle>
                                              <button
                                                  className="btn btn-primary d-flex align-items-center mx-xl-0 mx-2"
                                                  onClick={() => removeLastNews(item)}
                                              >
                                                  <i className="bi bi-x-circle icon-2 pe-2" />
                                                  <span>Remove</span>
                                              </button>
                                          </div>
                                      </div>
                                  </Card.Body>
                                  <Accordion.Collapse eventKey={idx}>
                                      <div className="d-flex flex-column justify-content-around px-4 py-4 border-top">
                                          <span className="text-primary  font-2 py-2 ps-1">Denumire</span>
                                          <Form.Control
                                              aria-label="Title"
                                              placeholder={item.title}
                                              value={title}
                                              onChange={(e) => {
                                                  setTitle(e.target.value);
                                              }}
                                          />
                                          <span className="text-primary  font-2 py-2 ps-1">Descriere</span>
                                          <ReactQuill
                                              theme="snow"
                                              value={article}
                                              placeholder={item.article}
                                              modules={modules}
                                              formats={formats}
                                              onChange={setArticle}
                                          />
                                          <button
                                              className="btn btn-primary d-flex align-items-center justify-content-center mx-xl-0 my-4"
                                              onClick={() => editLastNews(item)}
                                          >
                                              <i className="bi bi-check icon-2 pe-2" />
                                              <span>Confirm</span>
                                          </button>
                                      </div>
                                  </Accordion.Collapse>
                              </div>
                          ))
                        : null}
                </Accordion>

                {/* <div className='d-flex flex-column justify-content-around'>
					<span className='text-primary  font-2 py-2 ps-1'>Denumire</span>
					<Form.Control
						aria-label='Title'
						value={title}
						onChange={(e) => {
							setTitle(e.target.value);
						}}
					/>
					<span className='text-primary  font-2 py-2 ps-1'>Descrieree</span>
					<ReactQuill
						theme='snow'
						value={description}
						modules={modules}
						formats={formats}
						onChange={setDescription}
					/>
				</div> */}
            </div>
            <AddLastNewsModal newsType={'lastNews'} show={show} onHide={() => setShow(false)} />
        </div>
    );
});

export default LastNewsAdmin;
