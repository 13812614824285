import { useContext, useState, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { Context } from '..';
import { observer } from 'mobx-react-lite';
import Calendar from '../components/Calendar';
import { useNavigate } from 'react-router-dom';
import { CITY_NEWS_ROUTE, PARTNER_SUPPORTED_PROJECTS_ROUTE } from '../utils/consts';
import { fetchPartnerSupportedProjects } from '../http/resourcesServicesAPI';
import { Spinner } from 'react-bootstrap';

const PartnerSupportedProjects = observer(() => {
    const { resourcesServices } = useContext(Context);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchPartnerSupportedProjects()
            .then((data) => {
                resourcesServices.setPartnerSupportedProjects(data);
            })
            .finally(() => setLoading(false));
    }, []);
    if (loading) {
        return (
            <div className="d-flex align-items-center justify-content-center py-5">
                <Spinner animation={'grow'} />
            </div>
        );
    }
    return (
        <div className="d-flex flex-xl-row flex-column justify-content-center px-xl-4 px-2">
            <div className="py-4 px-5 border-bottom col-md-9">
                <div className="block-title pb-4">
                    <span>Proiecte Susținute de Partener</span>
                </div>

                <div className="d-flex flex-row flex-wrap align-items-center justify-content-around">
                    {resourcesServices.partnerSupportedProjects.count > 0
                        ? resourcesServices.partnerSupportedProjects.rows.map((item, idx) => {
                              return (
                                  <Card
                                      key={idx}
                                      className="my-3 shadow-sm border-0"
                                      onClick={() => navigate(PARTNER_SUPPORTED_PROJECTS_ROUTE + '/' + item.id)}
                                      style={{ width: '22rem', minHeight: '20rem' }}
                                      role="button"
                                  >
                                      <div
                                          className="h-100 rounded"
                                          style={{
                                              minHeight: '20vh',
                                              background: `url(${
                                                  process.env.REACT_APP_API_URL + item.img
                                              })  center center/cover no-repeat`,
                                          }}
                                      ></div>
                                      <Card.Body>
                                          <Card.Title className="mb-3 d-flex align-items-center justify-content-between">
                                              <span
                                                  className="border border-primary rounded text-primary p-2"
                                                  style={{ fontSize: '1rem' }}
                                              >
                                                  {item.date}
                                              </span>
                                          </Card.Title>
                                          <Card.Text className="text-primary truncate fw-bold">{item.title}</Card.Text>
                                      </Card.Body>
                                  </Card>
                              );
                          })
                        : null}
                </div>
            </div>
            <Calendar />
        </div>
    );
});

export default PartnerSupportedProjects;
