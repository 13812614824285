import React, { useContext, useEffect, useState } from 'react';
import { BrowserRouter, ScrollRestoration } from 'react-router-dom';
import './assets/styles/override.css';
import AppRouter from './components/AppRouter';
import References from './components/References';
import Useful from './components/Useful';
import Footer from './components/Footer';
import NavBar from './components/NavBar';
import { check } from './http/userAPI';
import { Spinner } from 'react-bootstrap';
import { Context } from '.';
import { observer } from 'mobx-react-lite';

const App = observer(() => {
    const { user } = useContext(Context);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        check()
            .then((data) => {
                if (data) {
                    user.setUser(data);
                    user.setIsAuth(true);
                }
            })
            .finally(() => setLoading(false));
    }, []);

    if (loading) {
        return <Spinner animation={'grow'} />;
    }
    return (
        <BrowserRouter>
            <NavBar />
            <AppRouter />
            <Useful />
            <References />
            <Footer />
        </BrowserRouter>
    );
});

export default App;
