import React, { useContext, useState, useEffect } from 'react';
import { Card, Button, Col, Row } from 'react-bootstrap';
import { Context } from '..';
import { observer } from 'mobx-react-lite';
import Calendar from '../components/Calendar';
import { useNavigate } from 'react-router-dom';
import { LAST_NEWS_ROUTE } from '../utils/consts';
import { Spinner } from 'react-bootstrap';
import { fetchLastNews } from '../http/NewsAPI';
import parse from 'html-react-parser';

const LastNewsList = observer(() => {
    const { lastNews } = useContext(Context);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        fetchLastNews()
            .then((data) => {
                lastNews.setLastNews(data);
            })
            .finally(() => setLoading(false));
    }, []);

    if (loading) {
        return (
            <div className="d-flex align-items-center justify-content-center py-5">
                <Spinner animation={'grow'} />
            </div>
        );
    }
    return (
        <div className="d-flex flex-xl-row flex-column justify-content-center px-xl-4 px-2">
            <div className="py-4 px-5 border-bottom col-md-9">
                <div className="block-title pb-4">
                    <span>Noutățile raionului</span>
                </div>
                <Row xs={1} md={3} xl={5} className="g-3">
                    {lastNews.lastNews.rows.map((item, idx) => (
                        <Col key={idx}>
                            <Card className="shadow-sm h-100 border-0">
                                {/* <Card.Img variant="top" src="https://picsum.photos/160/100" /> */}
                                <Card.Body className="d-flex flex-column justify-content-between align-items-center">
                                    <a href="#" className="text-decoration-none text-dark">
                                        <Card.Title>{item.title}</Card.Title>
                                    </a>
                                    <Card.Text className="truncate">{parse(item.article)}</Card.Text>
                                    <Button onClick={() => navigate(LAST_NEWS_ROUTE + '/' + item.id)}>
                                        Citește mai mult...
                                    </Button>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </div>
            <Calendar />
        </div>
    );
});

export default LastNewsList;
