import { makeAutoObservable } from 'mobx';

export default class ResourceServicesStore {
    constructor() {
        // Menu Generation Start
        this._localDocs = [
            {
                id: 1,
                title: 'General',
                icon: 'bi-file-earmark-text',
                link: '/ld_general',
            },
            {
                id: 2,
                title: 'Administraţie publică locală',
                icon: 'bi-file-earmark-text',
                link: '/ld_local_public_administration',
            },
            {
                id: 3,
                title: 'Buget şi finanţe',
                icon: 'bi-file-earmark-text',
                link: '/ld_budget_finances',
            },
            {
                id: 4,
                title: 'Comerț',
                icon: 'bi-file-earmark-text',
                link: '/ld_commerce',
            },
            {
                id: 5,
                title: 'Impozite și taxe locale',
                icon: 'bi-file-earmark-text',
                link: '/ld_local_taxes',
            },
            {
                id: 6,
                title: 'Construcţii',
                icon: 'bi-file-earmark-text',
                link: '/ld_construction',
            },
            {
                id: 7,
                title: 'Raporturi de serviciu/muncă',
                icon: 'bi-file-earmark-text',
                link: '/ld_employment_relationships',
            },
            {
                id: 8,
                title: 'Relaţii funciare',
                icon: 'bi-file-earmark-text',
                link: '/ld_land_tenure',
            },
            {
                id: 9,
                title: 'Organizarea licitaţiilor',
                icon: 'bi-file-earmark-text',
                link: '/ld_organizing_tenders',
            },
            {
                id: 10,
                title: 'Gestionarea patrimoniului public',
                icon: 'bi-file-earmark-text',
                link: '/ld_managing_public_heritage',
            },
            {
                id: 11,
                title: 'Alte domenii',
                icon: 'bi-file-earmark-text',
                link: '/ld_other_domains',
            },
        ];
        this._serviceList = [
            {
                id: 1,
                title: 'Consultari Publice',
                icon: 'bi-info-circle',
                link: '/public_consultations',
            },
            {
                id: 2,
                title: 'Formulare Cereri',
                icon: 'bi-file-text',
                link: '/application_forms',
            },
            {
                id: 3,
                title: 'Politici și Strategii',
                icon: 'bi-file-earmark-bar-graph',
                link: '/policies_strategies',
            },
            {
                id: 4,
                title: 'Rapoarte Activitate',
                icon: 'bi-file-earmark-check',
                link: '/transparency',
            },
            {
                id: 5,
                title: 'Rețeaua Transport Public',
                icon: 'bi-bus-front',
                link: '/public_transport_network',
            },
            {
                id: 6,
                title: 'Inițiere Parteneriate Publice/Private',
                icon: 'bi-people',
                link: '/initiating_public_private_partnerships',
            },
            {
                id: 7,
                title: 'Calendari Activitati',
                icon: 'bi-calendar3',
                link: '/events',
            },
        ];
        this._investments = [
            {
                id: 1,
                title: 'Edineț în Cifre',
                icon: 'bi-clipboard2-data',
                link: '/location_in_numbers',
            },
            {
                id: 2,
                title: 'Spatii Pentru Locatiune',
                icon: 'bi-building',
                link: 'https://drive.google.com/file/d/1g26PEtDTFUDNpMM4lXPaByZDnZ6L_7o5/view',
            },
            {
                id: 3,
                title: 'Istorii de Succes',
                icon: 'bi-trophy',
                link: '/success_stories',
            },
            {
                id: 4,
                title: 'Strategia de Dezvoltare RE',
                icon: 'bi-briefcase',
                link: '/location_development_strategy',
            },
            {
                id: 5,
                title: 'Sectoare Cheie',
                icon: 'bi-key',
                link: '/key_sectors',
            },
            {
                id: 6,
                title: 'Proiecte Susținute de Partener',
                icon: 'bi-person-vcard',
                link: '/partner_supported_projects',
            },
            {
                id: 7,
                title: 'Inițierea Afacerii',
                icon: 'bi-building-gear',
                link: '/starting_business',
            },
        ];
        this._visit = [
            {
                id: 1,
                title: 'Trasee Turistice',
                icon: 'bi-signpost-2',
                link: '/tourist_routes',
            },
            {
                id: 2,
                title: 'Hoteluri',
                icon: 'bi-buildings',
                link: '/hotels',
            },
            {
                id: 3,
                title: 'Muzee',
                icon: 'bi-bank',
                link: '/museums',
            },
            {
                id: 4,
                title: 'Parcuri',
                icon: 'bi-tree',
                link: '/parks',
            },
            {
                id: 5,
                title: 'Activități Turistice',
                icon: 'bi-person-arms-up',
                link: '/tourist_activities',
            },
            {
                id: 6,
                title: 'Clădiri istorice',
                icon: 'bi-bank2',
                link: '/historic_buildings',
            },
            {
                id: 7,
                title: 'Monumente',
                icon: 'bi-align-bottom',
                link: '/monuments',
            },
            {
                id: 8,
                title: 'Personalități marcante',
                icon: 'bi-hand-thumbs-up',
                link: '/notable_personalities',
            },
            {
                id: 9,
                title: 'Pentru Copii',
                icon: 'bi-emoji-smile',
                link: '/for_kids',
            },
            {
                id: 10,
                title: 'Biserici și Mănăstiri',
                icon: 'bi-geo-alt',
                link: '/churches_monasteries',
            },
        ];
        this._cece = [
            {
                id: 1,
                title: 'Rapoarte',
                icon: 'bi-file-earmark-pdf',
                link: '/cece_reports',
            },
            {
                id: 2,
                title: 'Procese Verbale',
                icon: 'bi-file-earmark-text',
                link: '/cece_verbal_procedure',
            },
            {
                id: 3,
                title: 'Hotărâri',
                icon: 'bi-file-check',
                link: '/decisions',
            },
            {
                id: 4,
                title: 'Organe Ierarhic Inferioare',
                icon: 'bi-diagram-3',
                link: '/lower_hierarchical_organs',
            },
            {
                id: 5,
                title: 'Anunțuri',
                icon: 'bi-newspaper',
                link: '/announcements',
            },
            {
                id: 6,
                title: 'Informații',
                icon: 'bi-info-lg',
                link: '/information',
            },
        ];

        // Menu Generation End

        this._icons = [
            'bi-map',
            'bi-person',
            'bi-building',
            'bi-globe2',
            'bi-flag',
            'bi-calendar-check',
            'bi-globe-europe-africa',
            'bi-graph-up',
            'bi-list-nested',
            'bi-buildings',
            'bi-cash-stack',
            'bi-receipt',
        ];
        this._extraordinaryCommittees = [];
        this._locationInNumbers = [
            // { id: 1, name: "Teritoriul", icon: "bi-map", value: "932,92 km²" },
            // { id: 2, name: "Locuitori", icon: "bi-person", value: "15.5 mii" },
            // { id: 3, name: "Întreprinderi", icon: "bi-building", value: "???" },
            // {
            //   id: 4,
            //   name: "La nivel mondial, după accesibilitatea Gigabit Internet",
            //   icon: "bi-globe2",
            //   value: "Locul 5",
            // },
            // {
            //   id: 5,
            //   name: "Impozit pe profit (7% fermieri, 6% ZEL)",
            //   icon: "bi-flag",
            //   value: "12%",
            // },
            // {
            //   id: 6,
            //   name: "Prețul arendei încăperilor nelocuibile",
            //   icon: "bi-calendar-check",
            //   value: "Minim 3 €/m²",
            // },
            // {
            //   id: 7,
            //   name: "În top cele mai mari...",
            //   icon: "bi-globe-europe-africa",
            //   value: "Locul 47",
            // },
            // {
            //   id: 8,
            //   name: "Investiţii în active imobilizate în prețuri curente",
            //   icon: "bi-graph-up",
            //   value: "10 761,7 mil lei",
            // },
            // {
            //   id: 9,
            //   name: "În clasamentul mondial (190 de țări), privind ușurința de a face afaceri”",
            //   icon: "bi-list-nested",
            //   value: "Poziția 48",
            // },
            // {
            //   id: 10,
            //   name: "Înfrațite cu mun. Chișinău (Grenoble, Reggio nell'Emilia, Mannheim, Sacramento, Odesa, București, Kiev, Erevan, Tel Aviv, Minsk, Ankara, Iași, Piatra Neamț,Tbilisi, Alba Iulia, Așdod, Greensboro, Patras, Suceava)",
            //   icon: "bi-buildings",
            //   value: "10 orașe",
            // },
            // {
            //   id: 11,
            //   name: "Câștigul salarial mediu lunar brut în municipiul Chișinău",
            //   icon: "bi-cash-stack",
            //   value: "10 020,4 lei",
            // },
            // {
            //   id: 12,
            //   name: "Produsul intern brut al municipiul Chișinău",
            //   icon: "bi-receipt",
            //   value: "40,1 mld lei",
            // },
        ];
        this._applicationForms = [];
        this._policiesStrategies = [];
        this._publicTransportNetwork = [];
        this._initiatingPublicPrivatePartnerships = [];
        this._successStories = [];
        this._locationDevelopmentStrategy = [];
        this._keySectors = [];
        this._partnerSupportedProjects = [];
        this._startingBusiness = [];
        this._touristRoutes = [];
        this._hotels = [];
        this._museums = [];
        this._parks = [];
        this._touristActivities = [];
        this._historicBuildings = [];
        this._monuments = [];
        this._notablePersonalities = [];
        this._forKids = [];
        this._churchesMonasteries = [];
        this._reports = [];
        this._cece_verbal_procedure = [];
        this._cece_decisions = [];
        this._lower_hierarchical_organs = [];
        this._cece_announcements = [];
        this._cece_information = [];
        makeAutoObservable(this);
    }

    setLocationInNumbers(locationInNumbers) {
        this._locationInNumbers = locationInNumbers;
    }

    get locationInNumbers() {
        return this._locationInNumbers;
    }
    setApplicationForms(applicationForms) {
        this._applicationForms = applicationForms;
    }

    get applicationForms() {
        return this._applicationForms;
    }

    setInitiatingPublicPrivatePartnerships(initiatingPublicPrivatePartnerships) {
        this._initiatingPublicPrivatePartnerships = initiatingPublicPrivatePartnerships;
    }

    get initiatingPublicPrivatePartnerships() {
        return this._initiatingPublicPrivatePartnerships;
    }

    setServiceList(serviceList) {
        this._serviceList = serviceList;
    }

    get serviceList() {
        return this._serviceList;
    }

    setInvestments(investments) {
        this._investments = investments;
    }

    get investments() {
        return this._investments;
    }

    setPublicTransportNetwork(publicTransportNetwork) {
        this._publicTransportNetwork = publicTransportNetwork;
    }

    get publicTransportNetwork() {
        return this._publicTransportNetwork;
    }

    setVisit(visit) {
        this._visit = visit;
    }

    get visit() {
        return this._visit;
    }

    setPoliciesStrategies(policiesStrategies) {
        this._policiesStrategies = policiesStrategies;
    }

    get policiesStrategies() {
        return this._policiesStrategies;
    }

    setCece(cece) {
        this._cece = cece;
    }
    get cece() {
        return this._cece;
    }

    setSuccessStories(successStories) {
        this._successStories = successStories;
    }
    get successStories() {
        return this._successStories;
    }

    setLocationDevelopmentStrategy(locationDevelopmentStrategy) {
        this._locationDevelopmentStrategy = locationDevelopmentStrategy;
    }
    get locationDevelopmentStrategy() {
        return this._locationDevelopmentStrategy;
    }

    setPartnerSupportedProjects(partnerSupportedProjects) {
        this._partnerSupportedProjects = partnerSupportedProjects;
    }
    get partnerSupportedProjects() {
        return this._partnerSupportedProjects;
    }

    setTouristRoutes(touristRoutes) {
        this._touristRoutes = touristRoutes;
    }
    get touristRoutes() {
        return this._touristRoutes;
    }

    setHotels(hotels) {
        this._hotels = hotels;
    }
    get hotels() {
        return this._hotels;
    }

    setMuseums(museums) {
        this._museums = museums;
    }
    get museums() {
        return this._museums;
    }

    setParks(parks) {
        this._parks = parks;
    }
    get parks() {
        return this._parks;
    }

    setTouristActivities(touristActivities) {
        this._touristActivities = touristActivities;
    }
    get touristActivities() {
        return this._touristActivities;
    }

    setHistoricBuildings(historicBuildings) {
        this._historicBuildings = historicBuildings;
    }
    get historicBuildings() {
        return this._historicBuildings;
    }

    setMonuments(monuments) {
        this._monuments = monuments;
    }
    get monuments() {
        return this._monuments;
    }

    setNotablePersonalities(notablePersonalities) {
        this._notablePersonalities = notablePersonalities;
    }
    get notablePersonalities() {
        return this._notablePersonalities;
    }

    setForKids(forKids) {
        this._forKids = forKids;
    }
    get forKids() {
        return this._forKids;
    }

    setChurchesMonasteries(churchesMonasteries) {
        this._churchesMonasteries = churchesMonasteries;
    }
    get churchesMonasteries() {
        return this._churchesMonasteries;
    }

    setReports(reports) {
        this._reports = reports;
    }
    get reports() {
        return this._reports;
    }

    setCECEVerbalProcedure(cece_verbal_procedure) {
        this._cece_verbal_procedure = cece_verbal_procedure;
    }
    get cece_verbal_procedure() {
        return this._cece_verbal_procedure;
    }

    setCECEDecisions(cece_decisions) {
        this._cece_decisions = cece_decisions;
    }
    get cece_decisions() {
        return this._cece_decisions;
    }

    setLowerHierarchicalOrgans(lower_hierarchical_organs) {
        this._lower_hierarchical_organs = lower_hierarchical_organs;
    }
    get lower_hierarchical_organs() {
        return this._lower_hierarchical_organs;
    }

    setCECEAnnouncements(cece_announcements) {
        this._cece_announcements = cece_announcements;
    }
    get cece_announcements() {
        return this._cece_announcements;
    }

    setCECEInformation(cece_information) {
        this._cece_information = cece_information;
    }
    get cece_information() {
        return this._cece_information;
    }
    setIcons(icons) {
        this._icons = icons;
    }
    get icons() {
        return this._icons;
    }
    setKeySectors(keySectors) {
        this._keySectors = keySectors;
    }
    get keySectors() {
        return this._keySectors;
    }
    setStartingBusiness(startingBusiness) {
        this._startingBusiness = startingBusiness;
    }
    get startingBusiness() {
        return this._startingBusiness;
    }

    setLocalDocs(localDocs) {
        this._localDocs = localDocs;
    }
    get localDocs() {
        return this._localDocs;
    }
}
