import { useState } from 'react';
import { Form, Modal, Button } from 'react-bootstrap';
import {
    createBiography,
    createDraftDecisions,
    createMeetings,
    createOrganization,
    createRegulations,
    createSpecialCommittees,
    createSpecialCommitteesMeets,
    createVerbalProcedure,
} from '../../../http/navLinksAPI';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const AddAdminModal = ({ show, onHide, selectedTab }) => {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [author, setAuthor] = useState('');
    const [img, setImg] = useState([null]);
    const [files, setFiles] = useState([null]);

    // Org
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [role, setRole] = useState('');
    const [birthdate, setBirthdate] = useState('');
    const [address, setAddress] = useState('');
    const [phone, setPhone] = useState('');
    const [fax, setFax] = useState('');
    const [email, setEmail] = useState('');

    // CommitteeMembers
    const [committeeTitle, setCommitteeTitle] = useState('');
    const [committeeMembers, setCommitteeMembers] = useState([]);

    // Bio
    const [interships, setInterships] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [profExperiences, setProfExperiences] = useState([]);
    const [studies, setStudies] = useState([]);

    // BioData Start
    const addInterships = () => {
        setInterships([...interships, { title: '', description: '', number: Date.now() }]);
    };

    const removeInterships = (number) => {
        setInterships(interships.filter((i) => i.number !== number));
    };

    const changeInterships = (key, value, number) => {
        setInterships(interships.map((i) => (i.number === number ? { ...i, [key]: value } : i)));
    };
    // Next
    const addLanguages = () => {
        setLanguages([...languages, { title: '', description: '', number: Date.now() }]);
    };

    const removeLanguages = (number) => {
        setLanguages(languages.filter((i) => i.number !== number));
    };

    const changeLanguages = (key, value, number) => {
        setLanguages(languages.map((i) => (i.number === number ? { ...i, [key]: value } : i)));
    };
    // Next
    const addProfExperiences = () => {
        setProfExperiences([...profExperiences, { title: '', description: '', number: Date.now() }]);
    };

    const removeProfExperiences = (number) => {
        setProfExperiences(profExperiences.filter((i) => i.number !== number));
    };

    const changeProfExperiences = (key, value, number) => {
        setProfExperiences(profExperiences.map((i) => (i.number === number ? { ...i, [key]: value } : i)));
    };
    // Next
    const addStudies = () => {
        setStudies([...studies, { title: '', description: '', number: Date.now() }]);
    };

    const removeStudies = (number) => {
        setStudies(studies.filter((i) => i.number !== number));
    };

    const changeStudies = (key, value, number) => {
        setStudies(studies.map((i) => (i.number === number ? { ...i, [key]: value } : i)));
    };
    // BioData End

    const addCommitteeMember = () => {
        setCommitteeMembers([...committeeMembers, { full_name: '', party_name: '', number: Date.now() }]);
    };

    const removeCommitteeMember = (number) => {
        setCommitteeMembers(committeeMembers.filter((i) => i.number !== number));
    };

    const changeCommitteeMember = (key, value, number) => {
        setCommitteeMembers(committeeMembers.map((i) => (i.number === number ? { ...i, [key]: value } : i)));
    };

    const addItem = () => {
        const formData = new FormData();
        formData.append('title', title);
        formData.append('description', description);
        formData.append('img', img);
        formData.append('author', author);
        for (const file of files) {
            formData.append('files', file);
        }

        formData.append('first_name', firstName);
        formData.append('last_name', lastName);
        formData.append('role', role);
        formData.append('birthdate', birthdate);
        formData.append('address', address);
        formData.append('phone', phone);
        formData.append('fax', fax);
        formData.append('email', email);

        formData.append('intership', JSON.stringify(interships));
        formData.append('languages', JSON.stringify(languages));
        formData.append('profExp', JSON.stringify(profExperiences));
        formData.append('studies', JSON.stringify(studies));

        formData.append('committeeTitle', committeeTitle);
        formData.append('committeeMembers', JSON.stringify(committeeMembers));

        switch (selectedTab) {
            case 'Conducerea':
                createBiography(formData).then(() => window.location.reload());
                break;
            case 'Organigrama':
                createOrganization(formData).then(() => window.location.reload());
                break;
            case 'Comisiile':
                createSpecialCommittees(formData).then(() => window.location.reload());
                break;
            case 'Regulamente':
                createRegulations(formData).then(() => window.location.reload());
                break;
            case 'Ședințele':
                createMeetings(formData).then(() => window.location.reload());
                break;
            case 'Procese Verbale':
                createVerbalProcedure(formData).then(() => window.location.reload());
                break;
            case 'Ședințe Comisii de Specialitate':
                createSpecialCommitteesMeets(formData).then(() => window.location.reload());
                break;
            case 'Proiecte de Decizii':
                createDraftDecisions(formData).then(() => window.location.reload());
                break;
        }
    };

    const modules = {
        toolbar: [
            [{ size: ['small', false, 'large', 'huge'] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }, { align: [] }],
            ['link', 'image'],
            [
                {
                    color: [
                        '#000000',
                        '#e60000',
                        '#ff9900',
                        '#ffff00',
                        '#008a00',
                        '#0066cc',
                        '#9933ff',
                        '#ffffff',
                        '#facccc',
                        '#ffebcc',
                        '#ffffcc',
                        '#cce8cc',
                        '#cce0f5',
                        '#ebd6ff',
                        '#bbbbbb',
                        '#f06666',
                        '#ffc266',
                        '#ffff66',
                        '#66b966',
                        '#66a3e0',
                        '#c285ff',
                        '#888888',
                        '#a10000',
                        '#b26b00',
                        '#b2b200',
                        '#006100',
                        '#0047b2',
                        '#6b24b2',
                        '#444444',
                        '#5c0000',
                        '#663d00',
                        '#666600',
                        '#003700',
                        '#002966',
                        '#3d1466',
                        'custom-color',
                    ],
                },
            ],
        ],
    };

    const formats = [
        'header',
        'height',
        'bold',
        'italic',
        'underline',
        'strike',
        'blockquote',
        'list',
        'color',
        'bullet',
        'indent',
        'link',
        'image',
        'align',
        'size',
    ];

    const selectFile = (e) => {
        setImg(e.target.files[0]);
    };

    const selectMultipleFiles = (e) => {
        const files = Array.from(e.target.files);
        setFiles(files);
    };

    return (
        <Modal show={show} onHide={onHide} className="add-news-modal border-primary">
            <Modal.Header closeButton={onHide} className="bg-primary" closeVariant="white">
                <Modal.Title className="text-white">{selectedTab}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {selectedTab === 'Conducerea' ? (
                    <div className="d-flex flex-column justify-content-around px-4 py-4">
                        <span className="text-primary font-2 py-2 ps-1">Fotografie</span>
                        <Form.Control
                            type={'file'}
                            onChange={selectFile}
                            aria-label="PageTitle"
                            aria-describedby="basic-addon"
                        />
                        <span className="text-primary  font-2 py-2 ps-1">Nume</span>
                        <Form.Control
                            aria-label="Title"
                            value={firstName}
                            onChange={(e) => {
                                setFirstName(e.target.value);
                            }}
                        />
                        <span className="text-primary  font-2 py-2 ps-1">Prenume</span>
                        <Form.Control
                            aria-label="Title"
                            value={lastName}
                            onChange={(e) => {
                                setLastName(e.target.value);
                            }}
                        />
                        <span className="text-primary  font-2 py-2 ps-1">Funcția</span>
                        <Form.Control
                            aria-label="Title"
                            value={role}
                            onChange={(e) => {
                                setRole(e.target.value);
                            }}
                        />
                        <span className="text-primary  font-2 py-2 ps-1">Adresa</span>
                        <Form.Control
                            aria-label="Title"
                            value={address}
                            onChange={(e) => {
                                setAddress(e.target.value);
                            }}
                        />
                        <span className="text-primary  font-2 py-2 ps-1">Data Nasterii</span>
                        <Form.Control
                            aria-label="Title"
                            value={birthdate}
                            onChange={(e) => {
                                setBirthdate(e.target.value);
                            }}
                        />
                        <span className="text-primary  font-2 py-2 ps-1">E-Mail</span>
                        <Form.Control
                            aria-label="Title"
                            value={email}
                            onChange={(e) => {
                                setEmail(e.target.value);
                            }}
                        />
                        <span className="text-primary  font-2 py-2 ps-1">Nr. de Telefon</span>
                        <Form.Control
                            aria-label="Title"
                            value={phone}
                            onChange={(e) => {
                                setPhone(e.target.value);
                            }}
                        />
                        <span className="text-primary  font-2 py-2 ps-1">Nr. de Fax</span>
                        <Form.Control
                            aria-label="Title"
                            value={fax}
                            onChange={(e) => {
                                setFax(e.target.value);
                            }}
                        />
                        <span className="text-primary  font-2 py-2 ps-1">Adaugarea Studiilor</span>
                        <Button onClick={addStudies}>Adauga</Button>
                        {studies.map((i) => (
                            <div
                                className="d-flex flex-xl-row flex-column align-items-center justify-content-around"
                                key={i.number}
                            >
                                <Form.Control
                                    value={i.title}
                                    className="me-xl-5"
                                    onChange={(e) => changeStudies('title', e.target.value, i.number)}
                                    placeholder={'Anii de Studii'}
                                />
                                <Form.Control
                                    value={i.description}
                                    className="me-xl-5"
                                    onChange={(e) => changeStudies('description', e.target.value, i.number)}
                                    placeholder={'Studiile Terminate'}
                                />
                                <Button
                                    variant={'outline-danger'}
                                    className="my-sm-4"
                                    onClick={() => removeStudies(i.number)}
                                >
                                    Sterge
                                </Button>
                            </div>
                        ))}
                        <span className="text-primary  font-2 py-2 ps-1">Experienta Professionala</span>
                        <Button onClick={addProfExperiences}>Adauga</Button>
                        {profExperiences.map((i) => (
                            <div
                                className="d-flex flex-xl-row flex-column align-items-center justify-content-around"
                                key={i.number}
                            >
                                <Form.Control
                                    value={i.title}
                                    className="me-xl-5"
                                    onChange={(e) => changeProfExperiences('title', e.target.value, i.number)}
                                    placeholder={'Perioada de Activitate'}
                                />
                                <Form.Control
                                    value={i.description}
                                    className="me-xl-5"
                                    onChange={(e) => changeProfExperiences('description', e.target.value, i.number)}
                                    placeholder={'Activitatea'}
                                />
                                <Button
                                    variant={'outline-danger'}
                                    className="my-sm-4"
                                    onClick={() => removeProfExperiences(i.number)}
                                >
                                    Sterge
                                </Button>
                            </div>
                        ))}
                        <span className="text-primary  font-2 py-2 ps-1">Intership-urile Finisate</span>
                        <Button onClick={addInterships}>Adauga</Button>
                        {interships.map((i) => (
                            <div
                                className="d-flex flex-xl-row flex-column align-items-center justify-content-around"
                                key={i.number}
                            >
                                <Form.Control
                                    value={i.title}
                                    className="me-xl-5"
                                    onChange={(e) => changeInterships('title', e.target.value, i.number)}
                                    placeholder={'Perioada de Intership'}
                                />
                                <Form.Control
                                    value={i.description}
                                    className="me-xl-5"
                                    onChange={(e) => changeInterships('description', e.target.value, i.number)}
                                    placeholder={'Activitatea'}
                                />
                                <Button
                                    variant={'outline-danger'}
                                    className="my-sm-4"
                                    onClick={() => removeInterships(i.number)}
                                >
                                    Sterge
                                </Button>
                            </div>
                        ))}
                        <span className="text-primary  font-2 py-2 ps-1">Limbele Cunoscute</span>
                        <Button onClick={addLanguages}>Adauga</Button>
                        {languages.map((i) => (
                            <div
                                className="d-flex flex-xl-row flex-column align-items-center justify-content-around"
                                key={i.number}
                            >
                                <Form.Control
                                    value={i.title}
                                    className="me-xl-5"
                                    onChange={(e) => changeLanguages('title', e.target.value, i.number)}
                                    placeholder={'Limba'}
                                />
                                <Form.Control
                                    value={i.description}
                                    className="me-xl-5"
                                    onChange={(e) => changeLanguages('description', e.target.value, i.number)}
                                    placeholder={'Nivel'}
                                />
                                <Button
                                    variant={'outline-danger'}
                                    className="my-sm-4"
                                    onClick={() => removeLanguages(i.number)}
                                >
                                    Sterge
                                </Button>
                            </div>
                        ))}
                    </div>
                ) : null}

                {selectedTab === 'Comisiile' ? (
                    <div className="d-flex flex-column justify-content-around px-4 py-4">
                        <span className="text-primary  font-2 py-2 ps-1">Denumirea Comisiei</span>
                        <Form.Control
                            aria-label="Title"
                            value={committeeTitle}
                            onChange={(e) => {
                                setCommitteeTitle(e.target.value);
                            }}
                        />
                        <span className="text-primary  font-2 py-2 ps-1">Membrii comisiei</span>
                        <Button onClick={addCommitteeMember}>Adauga</Button>
                        {committeeMembers.map((i) => (
                            <div
                                className="d-flex flex-xl-row flex-column align-items-center justify-content-around"
                                key={i.number}
                            >
                                <Form.Control
                                    value={i.full_name}
                                    className="me-xl-5"
                                    onChange={(e) => changeCommitteeMember('full_name', e.target.value, i.number)}
                                    placeholder={'Nume și Prenume'}
                                />
                                <Form.Control
                                    value={i.party_name}
                                    className="me-xl-5"
                                    onChange={(e) => changeCommitteeMember('party_name', e.target.value, i.number)}
                                    placeholder={'Denumirea partidului'}
                                />
                                <Button
                                    variant={'outline-danger'}
                                    className="my-sm-4"
                                    onClick={() => removeCommitteeMember(i.number)}
                                >
                                    Sterge
                                </Button>
                            </div>
                        ))}
                    </div>
                ) : null}

                {selectedTab === 'Organigrama' ? (
                    <div className="d-flex flex-column justify-content-around px-4 py-4">
                        <span className="text-primary  font-2 py-2 ps-1">Denumire</span>
                        <Form.Control
                            aria-label="Title"
                            value={title}
                            onChange={(e) => {
                                setTitle(e.target.value);
                            }}
                        />
                        <span className="text-primary  font-2 py-2 ps-1">Descriere</span>
                        <ReactQuill
                            theme="snow"
                            value={description}
                            modules={modules}
                            formats={formats}
                            onChange={setDescription}
                        />
                        <span className="text-primary  font-2 py-2 ps-1">Fotografie</span>
                        <Form.Control
                            type={'file'}
                            onChange={selectFile}
                            aria-label="PageTitle"
                            aria-describedby="basic-addon"
                        />
                    </div>
                ) : null}
                {selectedTab !== 'Conducerea' && selectedTab !== 'Organigrama' && selectedTab !== 'Comisiile' ? (
                    <div className="d-flex flex-column justify-content-around px-4 py-4">
                        <span className="text-primary  font-2 py-2 ps-1">Denumire</span>
                        <Form.Control
                            aria-label="Title"
                            value={title}
                            onChange={(e) => {
                                setTitle(e.target.value);
                            }}
                        />
                        <span className="text-primary  font-2 py-2 ps-1">Descriere</span>
                        <ReactQuill
                            theme="snow"
                            value={description}
                            modules={modules}
                            formats={formats}
                            onChange={setDescription}
                        />
                        <span className="text-primary font-2 py-2 ps-1">Fotografie</span>
                        <Form.Control
                            type={'file'}
                            onChange={selectFile}
                            aria-label="PageTitle"
                            aria-describedby="basic-addon"
                        />
                        <span className="text-primary font-2 py-2 ps-1">Documente</span>
                        <Form.Control
                            type={'file'}
                            onChange={selectMultipleFiles}
                            aria-label="PageTitle"
                            aria-describedby="basic-addon"
                            multiple
                        />
                    </div>
                ) : null}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Close
                </Button>
                <Button
                    variant="primary"
                    onClick={() => {
                        addItem();
                        onHide();
                    }}
                >
                    Confirm
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddAdminModal;
