export const HOME_ROUTE = '/';
export const ADMIN_ROUTE = '/administration';
export const LOGIN_ROUTE = '/administration_login';
export const BIOGRAPHY_ROUTE = '/biography';
export const PUBLIC_SERVICE_ROUTE = '/public_service';
export const STREAM_ROUTE = '/stream';
export const CONTACTS_ROUTE = '/contacts';
export const EVENTS_ROUTE = '/events';
export const LAST_NEWS_ROUTE = '/last_news';
export const CITY_NEWS_ROUTE = '/city_news';
export const PUBLIC_INFORMATION_ROUTE = '/public_info';
export const DECISION_PROCESS_ROUTE = '/decision_process';
export const PUBLIC_NOTICES_ROUTE = '/public_notices';
export const DECENTRALIZED_SERVICES_ROUTE = '/decentralized_services';
export const MUNICIPAL_ENTERPRISES_ROUTE = '/municipal_enterprises';
export const NGOV_ORGANIZATIONS_ROUTE = '/ngov_organizations';
export const ORGANIZATION_ROUTE = '/organization';
export const BUSINESS_ROUTE = '/business';
export const HISTORY_ROUTE = '/history';
export const PRESIDENTS_MESSAGE_ROUTE = '/message';
export const COUNCILLORS_LIST_ROUTE = '/councillors_list';
export const SPECIALIZED_COMMITTEES_ROUTE = '/specialized_committees';
export const REGULATIONS_ROUTE = '/regulations';
export const MEETINGS_ROUTE = '/meetings';
export const VERBAL_PROCEDURE_ROUTE = '/verbal_procedure';
export const DRAFT_DECISIONS_ROUTE = '/draft_decisions';
export const LOCAL_DECISIONS_ACTS_ROUTE = '/local_decisions_acts';
export const SPECIALIZED_COMMITTEES_MEETS_ROUTE = '/special_committees_meets';
export const SOCIAL_ECONOMIC_INDICATORS_ROUTE = '/social_economic_indicators';
export const DISTRICT_BUDGET_ROUTE = '/district_budget';
export const TOURISM_MARKETING_PLAN_ROUTE = '/tourism_marketing_plan';
export const MANAGEMENT_ROUTE = '/management';
export const TRANSPARENCY_ROUTE = '/transparency';
export const RECOMENDATIONS_ROUTE = '/reports';
export const VACANCIES_ROUTE = '/vacancies';
export const DISTRICT_DECISIONS_ROUTE = '/district_decisions';
export const DISTRICT_PROVISIONS_ROUTE = '/district_provisions';
export const APPLICATION_FORMS_ROUTE = '/application_forms';
export const INITIATING_PUBLIC_PRIVATE_PARTNERSHIPS_ROUTE = '/initiating_public_private_partnerships';
export const INITIATION_ROUTE = '/initiation';
export const POLICIES_STRATEGIES_ROUTE = '/policies_strategies';
export const PUBLIC_TRANSPORT_NETWORK_ROUTE = '/public_transport_network';
export const CONSULTATIONS_ROUTE = '/consultations';
export const PUBLIC_CONSULTATIONS_ROUTE = '/public_consultations';
export const LOCATION_IN_NUMBERSS_ROUTE = '/location_in_numbers';
export const SUCCESS_STORIES_ROUTE = '/success_stories';
export const LOCATION_DEVELOPMENT_STRATEGY_ROUTE = '/location_development_strategy';
export const KEY_SECTORS_ROUTE = '/key_sectors';
export const PARTNER_SUPPORTED_PROJECTS_ROUTE = '/partner_supported_projects';
export const STARTING_BUSINESS_ROUTE = '/starting_business';
export const TOURIST_ROUTES_ROUTE = '/tourist_routes';
export const HOTELS_ROUTE = '/hotels';
export const MUSEUMS_ROUTE = '/museums';
export const PARKS_ROUTE = '/parks';
export const TOURIST_ACTIVITIES_ROUTE = '/tourist_activities';
export const HISTORIC_BUILDINGS_ROUTE = '/historic_buildings';
export const MONUMENTS_ROUTE = '/monuments';
export const NOTABLE_PERSONALITIES_ROUTE = '/notable_personalities';
export const FOR_KIDS_ROUTE = '/for_kids';
export const CHURCHES_MONASTERIES_ROUTE = '/churches_monasteries';
export const REPORTS_ROUTE = '/cece_reports';
export const INFORMATION_ROUTE = '/information';
export const DECISIONS_ROUTE = '/decisions';
export const ANNOUNCEMENTS_ROUTE = '/announcements';
export const LOWER_HIERARCHICAL_ORGANS_ROUTE = '/lower_hierarchical_organs';
export const CECE_VERBAL_PROCEDURE_ROUTE = '/cece_verbal_procedure';
export const PHOTO_GALLERY_ROUTE = '/photo_gallery';
export const ONLINE_MEETING_ROUTE = '/online_meeting';
export const HEAD_ONLINE_ROUTE = '/head_online';
export const EVENT_PAGE_ROUTE = '/event';
export const EUROPEAN_PROJECTS_ROUTE = '/project';
export const CITIZENS_HEARING_PROGRAM_ROUTE = '/citizens_hearing_program';
export const ACQUISITION_ROUTE = '/acquisition';
export const TENDERS_ROUTE = '/tenders';
export const COMMITTEE_MEETINGS_ROUTE = '/committee_meetings';
export const OTHER_ROUTE = '/other';
export const MAIN_BANNER_INFO = '/main_banner_info';
export const EXTRAORDINARY_COMMISSIONS_ROUTE = '/extraordinary_commissions';

export const LD_GENERAL_ROUTE = '/ld_general';
export const LD_LOCAL_PUBLIC_ADMINISTRATION_ROUTE = '/ld_local_public_administration';
export const LD_BUDGET_FINANCE_ROUTE = '/ld_budget_finances';
export const LD_COMMERCE_ROUTE = '/ld_commerce';
export const LD_LOCAL_TAXES_ROUTE = '/ld_local_taxes';
export const LD_CONSTRUCTION_ROUTE = '/ld_construction';
export const LD_EMPLOYMENT_RELATIONSHIPS_ROUTE = '/ld_employment_relationships';
export const LD_LAND_TENURE_ROUTE = '/ld_land_tenure';
export const LD_ORGANIZING_TENDERS_ROUTE = '/ld_organizing_tenders';
export const LD_MANAGING_PUBLIC_HERITAGE_ROUTE = '/ld_managing_public_heritage';
export const LD_OTHER_DOMAINS_ROUTE = '/ld_other_domains';

const ld_main_link =
    'https://actelocale.gov.md/widget_src/eyJzaG93X3BhZ2luYXRpb24iOnt9LCJzaG93X3NlYXJjaCI6dHJ1ZSwicmVzdWx0c19vbl9wYWdlIjoyMCwib2Zmc2V0IjowLCJhcGwiOiIxODUwNyIsImRhdGVfcmFuZ2UiOnsiZGF0ZV9mcm9tIjoiIiwiZGF0ZV90byI6IiJ9LCJzdGF0ZXMiOltdLCJkb21haW5zIjpb';

export const ld_general = ld_main_link + 'OV0sInR5cGVzIjpbXX0%3D';
export const ld_local_public_administration = ld_main_link + 'IjEiXSwidHlwZXMiOltdfQ%3D%3D';
export const ld_budget_and_finances = ld_main_link + 'Ml0sInR5cGVzIjpbXX0%3D';
export const ld_commerce = ld_main_link + 'MTBdLCJ0eXBlcyI6W119';
export const ld_local_taxes = ld_main_link + 'IjExIl0sInR5cGVzIjpbXX0%3D';
export const ld_constructions = ld_main_link + 'M10sInR5cGVzIjpbXX0%3D';
export const ld_service_work_reports = ld_main_link + 'NV0sInR5cGVzIjpbXX0%3D';
export const ld_land_relations = ld_main_link + 'Nl0sInR5cGVzIjpbXX0%3D';
export const ld_auction_organization = ld_main_link + 'N10sInR5cGVzIjpbXX0%3D';
export const ld_public_property_management = ld_main_link + 'NF0sInR5cGVzIjpbXX0%3D';
export const ld_other_areas = ld_main_link + 'OF0sInR5cGVzIjpbXX0%3D';
