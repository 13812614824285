import React, { useContext, useState, useEffect } from 'react';
import { Card, Button, Tabs, Tab } from 'react-bootstrap';
import { Context } from '..';
import { observer } from 'mobx-react-lite';
import Calendar from '../components/Calendar';
import { useNavigate } from 'react-router-dom';
import { CITY_NEWS_ROUTE, HOTELS_ROUTE } from '../utils/consts';
import { Spinner } from 'react-bootstrap';
import { fetchHotels } from '../http/resourcesServicesAPI';

const Hotels = observer(() => {
    const { resourcesServices } = useContext(Context);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchHotels()
            .then((data) => {
                resourcesServices.setHotels(data);
            })
            .finally(() => setLoading(false));
    }, []);
    if (loading) {
        return (
            <div className="d-flex align-items-center justify-content-center py-5">
                <Spinner animation={'grow'} />
            </div>
        );
    }
    return (
        <div className="d-flex flex-xl-row flex-column justify-content-center px-xl-4 px-2">
            <div className="py-4 px-5 border-bottom col-md-9">
                <div className="block-title pb-4">
                    <span>Hoteluri</span>
                </div>

                <div className="d-flex flex-row flex-wrap align-items-center justify-content-around">
                    {resourcesServices.hotels.count > 0
                        ? resourcesServices.hotels.rows.map((item, idx) => {
                              return (
                                  <Card key={idx} style={{ width: '22rem' }} className="my-3">
                                      <Card.Img variant="top" src={process.env.REACT_APP_API_URL + item.img} />
                                      <Card.Body>
                                          <Card.Title className="mb-3 d-flex align-items-center justify-content-between">
                                              <span
                                                  className="border border-primary rounded text-primary p-2"
                                                  style={{ fontSize: '1rem' }}
                                              >
                                                  {item.date}
                                              </span>
                                          </Card.Title>
                                          <Card.Text onClick={() => navigate(HOTELS_ROUTE + '/' + item.id)}>
                                              {item.title}
                                          </Card.Text>
                                      </Card.Body>
                                  </Card>
                              );
                          })
                        : null}
                </div>
            </div>
            <Calendar />
        </div>
    );
});

export default Hotels;
