import { useState, useContext, useEffect } from 'react';
import { Card, Spinner, Form, Accordion, useAccordionButton } from 'react-bootstrap';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { observer } from 'mobx-react-lite';
import { Context } from '../../index';
import AddEventModal from './Modals/AddEventModal';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { fetchCalendarEvents, updateOneCalendarEvents, removeOneCalendarEvents } from '../../http/calendarAPI';
import parse from 'html-react-parser';
import { EVENT_PAGE_ROUTE } from '../../utils/consts';
import { useNavigate } from 'react-router-dom';

const CalendarAdmin = observer(() => {
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(true);
    const { calendarEvents } = useContext(Context);
    const navigate = useNavigate();

    const modules = {
        toolbar: [
            [{ size: ['small', false, 'large', 'huge'] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }, { align: [] }],
            ['link', 'image'],
            [
                {
                    color: [
                        '#000000',
                        '#e60000',
                        '#ff9900',
                        '#ffff00',
                        '#008a00',
                        '#0066cc',
                        '#9933ff',
                        '#ffffff',
                        '#facccc',
                        '#ffebcc',
                        '#ffffcc',
                        '#cce8cc',
                        '#cce0f5',
                        '#ebd6ff',
                        '#bbbbbb',
                        '#f06666',
                        '#ffc266',
                        '#ffff66',
                        '#66b966',
                        '#66a3e0',
                        '#c285ff',
                        '#888888',
                        '#a10000',
                        '#b26b00',
                        '#b2b200',
                        '#006100',
                        '#0047b2',
                        '#6b24b2',
                        '#444444',
                        '#5c0000',
                        '#663d00',
                        '#666600',
                        '#003700',
                        '#002966',
                        '#3d1466',
                        'custom-color',
                    ],
                },
            ],
        ],
    };

    const formats = [
        'header',
        'height',
        'bold',
        'italic',
        'underline',
        'strike',
        'blockquote',
        'list',
        'color',
        'bullet',
        'indent',
        'link',
        'image',
        'align',
        'size',
    ];

    const [title, setTitle] = useState('');
    const [img, setImg] = useState([null]);
    const [description, setDescription] = useState('');
    const [organizer, setOrganizer] = useState('');
    const [location, setLocation] = useState('');
    const [date, setDate] = useState('');
    const [ps_date, setPS_Date] = useState('');
    const [time, setTime] = useState('');

    const editEvent = (data) => {
        const id = data.id;
        const formData = new FormData();
        formData.append('title', title);
        formData.append('img', img);
        formData.append('description', description);
        formData.append('organizer', organizer);
        formData.append('location', location);
        formData.append('date', date);
        formData.append('ps_date', ps_date);
        formData.append('time', time);
        updateOneCalendarEvents(formData, id).then(() => window.location.reload());
    };

    const CustomToggle = ({ children, eventKey, item }) => {
        const decoratedOnClick = useAccordionButton(eventKey, () => {
            setTitle(item.title);
            setDescription(item.description);
            setOrganizer(item.organizer);
            setLocation(item.location);
        });

        return (
            <button
                className="btn btn-primary d-flex align-items-center mb-xl-1 mx-xl-0 mx-2"
                onClick={decoratedOnClick}
            >
                {children}
            </button>
        );
    };

    const removeEvent = (item) => {
        removeOneCalendarEvents(item.id).then(() => window.location.reload());
    };

    const selectFile = (e) => {
        setImg(e.target.files[0]);
    };

    useEffect(() => {
        fetchCalendarEvents()
            .then((data) => {
                calendarEvents.setEvent(data);
            })
            .finally(() => setLoading(false));
    }, []);

    if (loading) {
        return (
            <div className="d-flex align-items-center justify-content-center py-5">
                <Spinner animation={'grow'} />
            </div>
        );
    }
    return (
        <div className="d-flex flex-column bg-white r-corner-radius w-100">
            <div className="d-flex justify-content-between align-items-center font-2 fw-bold text-primary shadow-sm ps-3">
                <span>Calendar Events</span>
                <button
                    className="btn btn-primary r-u-corner-radius"
                    style={{ height: 70, width: 70 }}
                    onClick={() => setShow(true)}
                >
                    <i className="bi bi-plus-circle icon-2" />
                </button>
            </div>
            <div className="d-flex flex-column px-3" style={{ height: '100%', overflow: 'auto' }}>
                <Accordion className="d-flex flex-column px-3">
                    {calendarEvents.events.rows.map((item, idx) => (
                        <div key={idx} className="shadow-sm border-0 my-2 px-4 rounded">
                            <Card.Body className="d-flex flex-column">
                                <div className="d-flex flex-xl-row flex-column align-items-center justify-content-between p-3">
                                    <div>
                                        <a
                                            onClick={() => navigate(EVENT_PAGE_ROUTE + '/' + item.id)}
                                            role="button"
                                            className="text-decoration-none text-dark"
                                        >
                                            <Card.Title className="text-primary truncate">
                                                {parse(item.title)}
                                            </Card.Title>
                                        </a>
                                        <div className="truncate">{parse(item.description)}</div>
                                        <div className="d-flex justify-content-around pt-3">
                                            <span className="border border-primary rounded text-primary py-1 px-2">
                                                {item.date}
                                            </span>
                                            <span className="border border-primary rounded text-primary py-1 px-2 ms-3">
                                                {item.time}
                                            </span>
                                            <span className="border border-primary rounded text-primary py-1 px-2 ms-3">
                                                {item.location}
                                            </span>
                                            <span className="border border-primary rounded text-primary py-1 px-2 ms-3">
                                                {item.organizer}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="d-flex flex-xl-column flex-row justify-content-around py-xl-0 py-3">
                                        <CustomToggle
                                            eventKey={idx}
                                            item={item}
                                            onClick={() => {
                                                useAccordionButton({ idx });
                                            }}
                                        >
                                            <i className="bi bi-pencil-square icon-2 pe-2" />
                                            <span>Edit</span>
                                        </CustomToggle>
                                        <button
                                            className="btn btn-primary d-flex align-items-center mx-xl-0 mx-2"
                                            onClick={(e) => removeEvent(item)}
                                        >
                                            <i className="bi bi-x-circle icon-2 pe-2" />
                                            <span>Remove</span>
                                        </button>
                                    </div>
                                </div>
                            </Card.Body>
                            <Accordion.Collapse eventKey={idx}>
                                <div className="d-flex flex-column justify-content-around">
                                    <span className="text-primary  font-2 py-2 ps-1">Data</span>
                                    <Form>
                                        <Form.Group>
                                            <Form.Control
                                                type="datetime-local"
                                                onChange={(data) => {
                                                    let dateData = new Date(data.target.value);
                                                    setDate(
                                                        dateData
                                                            .toLocaleDateString('en-US', {
                                                                day: '2-digit',
                                                                month: '2-digit',
                                                                year: 'numeric',
                                                            })
                                                            .toString()
                                                            .split('/')
                                                            .join('.')
                                                    );
                                                    setPS_Date(
                                                        dateData
                                                            .toLocaleDateString('en-US', {
                                                                weekday: 'short',
                                                                month: 'short',
                                                                day: 'numeric',
                                                                year: 'numeric',
                                                            })
                                                            .split(',')
                                                            .join('')
                                                    );
                                                    setTime(
                                                        dateData.toLocaleTimeString('it-IT', {
                                                            hour: 'numeric',
                                                            minute: 'numeric',
                                                        })
                                                    );
                                                }}
                                            />
                                        </Form.Group>
                                    </Form>
                                    <span className="text-primary  font-2 py-2 ps-1">Denumire</span>
                                    <Form.Control
                                        aria-label="Title"
                                        value={title}
                                        placeholder={item.title}
                                        onChange={(e) => setTitle(e.target.value)}
                                    />
                                    <span className="text-primary  font-2 py-2 ps-1">Descrieree</span>
                                    <ReactQuill
                                        theme="snow"
                                        value={description}
                                        modules={modules}
                                        placeholder={item.description}
                                        formats={formats}
                                        onChange={setDescription}
                                    />
                                    <span className="text-primary  font-2 py-2 ps-1">Fotografie</span>
                                    {/* <InputGroup className='mb-3'>
										<InputGroup.Text id='basic-addon'>Logo-ul</InputGroup.Text> */}
                                    <Form.Control
                                        type={'file'}
                                        onChange={selectFile}
                                        aria-label="PageTitle"
                                        aria-describedby="basic-addon"
                                    />
                                    <span className="text-primary font-2 py-2 ps-1">Organizatorul</span>
                                    <Form.Control
                                        aria-label="Title"
                                        value={organizer}
                                        placeholder={item.organizer}
                                        onChange={(e) => setOrganizer(e.target.value)}
                                    />
                                    <span className="text-primary font-2 py-2 ps-1">Locatia</span>
                                    <Form.Control
                                        aria-label="Title"
                                        placeholder={item.location}
                                        value={location}
                                        className="me-4 mb-4"
                                        onChange={(e) => setLocation(e.target.value)}
                                    />
                                    <button
                                        className="btn btn-primary d-flex align-items-center justify-content-center mx-xl-0 my-4"
                                        onClick={() => editEvent(item)}
                                    >
                                        <i className="bi bi-check icon-2 pe-2" />
                                        <span>Confirm</span>
                                    </button>
                                </div>
                            </Accordion.Collapse>
                        </div>
                    ))}
                </Accordion>
            </div>
            <AddEventModal show={show} onHide={() => setShow(false)} />
        </div>
    );
});

export default CalendarAdmin;
