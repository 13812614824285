import React, { useContext, useState, useEffect } from 'react';
import { Tabs, Tab, Accordion, Pagination } from 'react-bootstrap';
import { Context } from '..';
import {
    ACQUISITION_ROUTE,
    DECISION_PROCESS_ROUTE,
    DRAFT_DECISIONS_ROUTE,
    INITIATION_ROUTE,
    PUBLIC_CONSULTATIONS_ROUTE,
    PUBLIC_INFORMATION_ROUTE,
    RECOMENDATIONS_ROUTE,
    TENDERS_ROUTE,
    VACANCIES_ROUTE,
} from '../utils/consts';
import { Link } from 'react-router-dom';
import {
    fetchAcquisition,
    fetchConsultations,
    fetchInitiations,
    fetchRecommendations,
    fetchTenders,
    fetchVacancies,
} from '../http/informationProcessAPI';
import { Spinner } from 'react-bootstrap';
import { fetchDraftDecisions } from '../http/navLinksAPI';
import { observer } from 'mobx-react-lite';

const InformationProcess = observer(() => {
    const { informationProcess } = useContext(Context);

    const tenderPages = [];
    const acquisitionPages = [];
    const vacanciesPages = [];
    const consultationPages = [];
    const initiationPages = [];
    const recommendationsPages = [];
    const draftDecisionsPages = [];

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchTenders(1, 5)
            .then((data) => {
                informationProcess.setTenders(data.rows);
                informationProcess.setTendersTotalCount(data.count);
            })
            .then(() =>
                fetchAcquisition(1, 5)
                    .then((data) => {
                        informationProcess.setAcquisition(data.rows);
                        informationProcess.setAcquisitionTotalCount(data.count);
                    })
                    .then(() =>
                        fetchVacancies(1, 5)
                            .then((data) => {
                                informationProcess.setVacancies(data.rows);
                                informationProcess.setVacanciesTotalCount(data.count);
                            })
                            .then(() =>
                                fetchConsultations(1, 5)
                                    .then((data) => {
                                        informationProcess.setConsultation(data.rows);
                                        informationProcess.setConsultationTotalCount(data.count);
                                    })
                                    .then(() =>
                                        fetchInitiations(1, 5)
                                            .then((data) => {
                                                informationProcess.setInitiation(data.rows);
                                                informationProcess.setInitiationTotalCount(data.count);
                                            })
                                            .then(() =>
                                                fetchRecommendations(1, 5)
                                                    .then((data) => {
                                                        informationProcess.setRecommendations(data.rows);
                                                        informationProcess.setRecommendationsTotalCount(data.count);
                                                    })
                                                    .then(() =>
                                                        fetchDraftDecisions(1, 2)
                                                            .then((data) => {
                                                                informationProcess.setDraftDecisions(data.rows);
                                                                informationProcess.setDraftDecisionsTotalCount(
                                                                    data.count
                                                                );
                                                            })
                                                            .finally(() => setLoading(false))
                                                    )
                                            )
                                    )
                            )
                    )
            );
    }, [informationProcess]);

    useEffect(() => {
        fetchTenders(informationProcess.tendersPage, informationProcess.tendersLimit)
            .then((data) => {
                informationProcess.setTenders(data.rows);
                informationProcess.setTendersTotalCount(data.count);
            })
            .then(() =>
                fetchAcquisition(informationProcess.acquisitionPage, informationProcess.acquisitionLimit)
                    .then((data) => {
                        informationProcess.setAcquisition(data.rows);
                        informationProcess.setAcquisitionTotalCount(data.count);
                    })
                    .then(() =>
                        fetchVacancies(informationProcess.vacanciesPage, informationProcess.vacanciesLimit)
                            .then((data) => {
                                informationProcess.setVacancies(data.rows);
                                informationProcess.setVacanciesTotalCount(data.count);
                            })
                            .then(() =>
                                fetchConsultations(
                                    informationProcess.consultationPage,
                                    informationProcess.consultationLimit
                                )
                                    .then((data) => {
                                        informationProcess.setConsultation(data.rows);
                                        informationProcess.setConsultationTotalCount(data.count);
                                    })
                                    .then(() =>
                                        fetchInitiations(
                                            informationProcess.initiationPage,
                                            informationProcess.initiationLimit
                                        )
                                            .then((data) => {
                                                informationProcess.setInitiation(data.rows);
                                                informationProcess.setInitiationTotalCount(data.count);
                                            })
                                            .then(() =>
                                                fetchRecommendations(
                                                    informationProcess.recommendationsPage,
                                                    informationProcess.recommendationsLimit
                                                )
                                                    .then((data) => {
                                                        informationProcess.setRecommendations(data.rows);
                                                        informationProcess.setRecommendationsTotalCount(data.count);
                                                    })
                                                    .then(() =>
                                                        fetchDraftDecisions(
                                                            informationProcess.draftDecisionsPage,
                                                            informationProcess.draftDecisionsLimit
                                                        )
                                                            .then((data) => {
                                                                informationProcess.setDraftDecisions(data.rows);
                                                                informationProcess.setDraftDecisionsTotalCount(
                                                                    data.count
                                                                );
                                                            })
                                                            .finally(() => setLoading(false))
                                                    )
                                            )
                                    )
                            )
                    )
            );
    }, [
        informationProcess.tendersPage,
        informationProcess.acquisitionPage,
        informationProcess.vacanciesPage,
        informationProcess.consultationPage,
        informationProcess.initiationPage,
        informationProcess.recommendationsPage,
        informationProcess.draftDecisionsPage,
    ]);

    const tenderPageCount = Math.ceil(informationProcess.tendersTotalCount / informationProcess.tendersLimit);
    const acquisitionPageCount = Math.ceil(
        informationProcess.acquisitionTotalCount / informationProcess.acquisitionLimit
    );
    const vacanciesPageCount = Math.ceil(informationProcess.vacanciesTotalCount / informationProcess.vacanciesLimit);
    const consultationPageCount = Math.ceil(
        informationProcess.consultationTotalCount / informationProcess.consultationLimit
    );
    const initiationPageCount = Math.ceil(informationProcess.initiationTotalCount / informationProcess.initiationLimit);
    const recommendationsPageCount = Math.ceil(
        informationProcess.recommendationsTotalCount / informationProcess.recommendationsLimit
    );
    const draftDecisionsPageCount = Math.ceil(
        informationProcess.draftDecisionsTotalCount / informationProcess.draftDecisionsLimit
    );

    for (let i = 1; i <= tenderPageCount; i++) {
        tenderPages.push(i);
    }
    for (let i = 1; i <= acquisitionPageCount; i++) {
        acquisitionPages.push(i);
    }
    for (let i = 1; i <= vacanciesPageCount; i++) {
        vacanciesPages.push(i);
    }
    for (let i = 1; i <= consultationPageCount; i++) {
        consultationPages.push(i);
    }
    for (let i = 1; i <= initiationPageCount; i++) {
        initiationPages.push(i);
    }
    for (let i = 1; i <= recommendationsPageCount; i++) {
        recommendationsPages.push(i);
    }
    for (let i = 1; i <= draftDecisionsPageCount; i++) {
        draftDecisionsPages.push(i);
    }

    if (loading) {
        return (
            <div className="d-flex align-items-center justify-content-center py-5">
                <Spinner animation={'grow'} />
            </div>
        );
    }
    return (
        <div id="transparency" className="d-flex flex-xl-row flex-column px-xl-5 px-2 py-5 border-bottom">
            <div className="d-flex flex-column px-xl-3 col-xl-6 col-12">
                <Link
                    to={PUBLIC_INFORMATION_ROUTE}
                    className="d-flex justify-content-start align-items-center mb-5 text-decoration-none text-primary font-3 ps-xl-4 pt-5 pt-xl-0 pt-xl-0"
                >
                    Informații publice
                    <i className="bi bi-arrow-right ps-3 icon-3" />
                </Link>
                <Tabs
                    defaultActiveKey="tenders"
                    id="uncontrolled-tab-example"
                    className="d-flex align-items-center justify-content-center mb-3 text-primary fw-bold"
                >
                    <Tab eventKey="tenders" title="Licitaţii şi achiziţii">
                        <div className="d-flex flex-column">
                            <Accordion className="pb-4">
                                {informationProcess.tenders.map((item, idx) =>
                                    item.tenders_files.length > 0 ? (
                                        <Accordion.Item
                                            eventKey={'e_tender-' + idx}
                                            key={'tender-' + idx}
                                            className="border-0"
                                        >
                                            <Accordion.Header>
                                                <Link
                                                    className="fw-bold ps-xl-1 font-2 text-primary text-justify pe-4 text-decoration-none truncate"
                                                    to={TENDERS_ROUTE + '/' + item.id}
                                                >
                                                    {item.title}
                                                </Link>
                                            </Accordion.Header>
                                            <div className="d-flex justify-content-start py-1 ps-4">
                                                <span className="border rounded border-primary px-2 text-primary">
                                                    {item.date}
                                                </span>
                                            </div>
                                            <Accordion.Body>
                                                {item.tenders_files.map((sub_item, sub_idx) => (
                                                    <Link
                                                        to={process.env.REACT_APP_API_URL + sub_item.doc}
                                                        key={'tenders_files' + sub_idx}
                                                        className="text-primary text-decoration-none"
                                                    >
                                                        <div className="d-flex align-items-center">
                                                            <i
                                                                className={`bi bi-filetype-${sub_item.doc_type} icon-2 text-primary rounded ms-4 px-2 text-primary`}
                                                            />
                                                            <span
                                                                className="text-wrap"
                                                                style={{ textAlign: 'justify' }}
                                                            >
                                                                {sub_item.doc.split('.')[0]}
                                                            </span>
                                                        </div>
                                                    </Link>
                                                ))}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    ) : (
                                        <div className="d-flex flex-column mb-4 ps-4 ps-xl-0">
                                            <Link
                                                className="fw-bold font-2 ps-xl-4 text-primary text-justify pe-4 text-decoration-none truncate"
                                                to={TENDERS_ROUTE + '/' + item.id}
                                            >
                                                {item.title}
                                            </Link>
                                            <div className="d-flex justify-content-start py-2 ps-xl-4 pt-3">
                                                <span className="border rounded border-primary px-2 text-primary">
                                                    {item.date}
                                                </span>
                                            </div>
                                        </div>
                                    )
                                )}
                            </Accordion>
                            <Pagination className="d-flex justify-content-center align-items-center mt-5">
                                {tenderPages.map((page) => (
                                    <Pagination.Item
                                        key={'t-' + page}
                                        active={informationProcess.tendersPage === page}
                                        onClick={() => informationProcess.setTendersPage(page)}
                                    >
                                        {page}
                                    </Pagination.Item>
                                ))}
                            </Pagination>
                        </div>
                    </Tab>
                    <Tab eventKey="acquisition" title="Planuri de achiziții">
                        <div className="d-flex flex-column">
                            <Accordion className="pb-4">
                                {informationProcess.acquisition.map((item, idx) =>
                                    item.acquisition_files.length > 0 ? (
                                        <Accordion.Item
                                            eventKey={'e_acquisition-' + idx}
                                            key={'acquisition-' + idx}
                                            className="border-0"
                                        >
                                            <Accordion.Header>
                                                <Link
                                                    className="fw-bold ps-xl-1 font-2 text-primary text-justify pe-4 text-decoration-none truncate"
                                                    to={ACQUISITION_ROUTE + '/' + item.id}
                                                >
                                                    {item.title}
                                                </Link>
                                            </Accordion.Header>
                                            <div className="d-flex justify-content-start py-2 ps-4">
                                                <span className="border rounded border-primary px-2 text-primary">
                                                    {item.date}
                                                </span>
                                            </div>
                                            <Accordion.Body>
                                                {item.acquisition_files.map((sub_item, sub_idx) => (
                                                    <Link
                                                        to={process.env.REACT_APP_API_URL + sub_item.doc}
                                                        key={'acquisition_files' + sub_idx}
                                                        className="text-primary text-decoration-none"
                                                    >
                                                        <div className="d-flex align-items-center">
                                                            <i
                                                                className={`bi bi-filetype-${sub_item.doc_type} icon-2 text-primary rounded ms-4 px-2 text-primary`}
                                                            />
                                                            <span
                                                                className="text-wrap"
                                                                style={{ textAlign: 'justify' }}
                                                            >
                                                                {sub_item.doc.split('.')[0]}
                                                            </span>
                                                        </div>
                                                    </Link>
                                                ))}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    ) : (
                                        <div className="d-flex flex-column mb-4 ps-4 ps-xl-0">
                                            <Link
                                                className="fw-bold font-2 ps-xl-4 text-primary text-justify pe-4 text-decoration-none truncate"
                                                to={ACQUISITION_ROUTE + '/' + item.id}
                                            >
                                                {item.title}
                                            </Link>
                                            <div className="d-flex justify-content-start py-2 ps-xl-4  pt-3">
                                                <span className="border rounded border-primary px-2 text-primary">
                                                    {item.date}
                                                </span>
                                            </div>
                                        </div>
                                    )
                                )}
                            </Accordion>
                            <Pagination className="d-flex justify-content-center align-items-center mt-5">
                                {acquisitionPages.map((page) => (
                                    <Pagination.Item
                                        key={'a-' + page}
                                        active={informationProcess.acquisitionPage === page}
                                        onClick={() => informationProcess.setAcquisitionPage(page)}
                                    >
                                        {page}
                                    </Pagination.Item>
                                ))}
                            </Pagination>
                        </div>
                    </Tab>
                    <Tab eventKey="vacancies" title="Posturi vacante">
                        <div className="d-flex flex-column">
                            <Accordion className="pb-4">
                                {informationProcess.vacancies.map((item, idx) =>
                                    item.vacancies_files.length > 0 ? (
                                        <Accordion.Item
                                            eventKey={'e_vacancy-' + idx}
                                            key={'vacancy-' + idx}
                                            className="border-0"
                                        >
                                            <Accordion.Header>
                                                <Link
                                                    className="fw-bold font-2 text-primary text-justify pe-4 text-decoration-none truncate"
                                                    to={VACANCIES_ROUTE + '/' + item.id}
                                                >
                                                    {item.title}
                                                </Link>
                                            </Accordion.Header>
                                            <div className="d-flex justify-content-start py-2 ps-4">
                                                <span className="border rounded border-primary px-2 text-primary">
                                                    {item.date}
                                                </span>
                                            </div>
                                            <Accordion.Body>
                                                {item.vacancies_files.map((sub_item, sub_idx) => (
                                                    <Link
                                                        to={process.env.REACT_APP_API_URL + sub_item.doc}
                                                        key={'vacancies_files' + sub_idx}
                                                        className="text-primary text-decoration-none"
                                                    >
                                                        <div className="d-flex align-items-center">
                                                            <i
                                                                className={`bi bi-filetype-${sub_item.doc_type} icon-2 text-primary rounded ms-4 px-2 text-primary`}
                                                            />
                                                            <span
                                                                className="text-wrap"
                                                                style={{ textAlign: 'justify' }}
                                                            >
                                                                {sub_item.doc.split('.')[0]}
                                                            </span>
                                                        </div>
                                                    </Link>
                                                ))}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    ) : (
                                        <div className="d-flex flex-column mb-4 ps-4 ps-xl-0">
                                            <Link
                                                className="fw-bold font-2 ps-xl-4 text-primary text-justify pe-4 text-decoration-none truncate"
                                                to={VACANCIES_ROUTE + '/' + item.id}
                                            >
                                                {item.title}
                                            </Link>
                                            <div className="d-flex justify-content-start ps-xl-4 py-2 pt-3">
                                                <span className="border rounded border-primary px-2 text-primary">
                                                    {item.date}
                                                </span>
                                            </div>
                                        </div>
                                    )
                                )}
                            </Accordion>
                            <Pagination className="d-flex justify-content-center align-items-center mt-5">
                                {vacanciesPages.map((page) => (
                                    <Pagination.Item
                                        key={'v' + page}
                                        active={informationProcess.vacanciesPage === page}
                                        onClick={() => informationProcess.setVacanciesPage(page)}
                                    >
                                        {page}
                                    </Pagination.Item>
                                ))}
                            </Pagination>
                        </div>
                    </Tab>
                </Tabs>
            </div>
            <div className="d-flex flex-column px-xl-3 col-xl-6 col-12">
                <Link
                    to={DECISION_PROCESS_ROUTE}
                    className="d-flex justify-content-start align-items-center mb-5 text-decoration-none text-primary font-3 ps-xl-4 pt-5 pt-md-0 pt-xl-0"
                >
                    Procesul decizional <i className="bi bi-arrow-right ps-3 icon-b" />
                </Link>
                <Tabs
                    defaultActiveKey="initiation"
                    id="uncontrolled-tab-example"
                    className="d-flex align-items-center justify-content-center mb-3 text-primary fw-bold"
                >
                    <Tab eventKey="initiation" title="Inițiere proiect">
                        {informationProcess.initiation.map((item, idx) => (
                            <Link
                                key={'initiation-' + idx}
                                className="d-flex flex-column justify-content-start py-3 text-decoration-none px-4 ps-xl-0"
                                to={INITIATION_ROUTE + '/' + item.id}
                            >
                                <span className="fw-bold font-2 text-primary text-justify truncate">{item.title}</span>
                                <div className="d-flex justify-content-start py-2">
                                    <span className="border rounded border-primary px-2 text-primary">{item.date}</span>
                                </div>
                            </Link>
                        ))}
                        <Pagination className="d-flex justify-content-center align-items-center mt-5">
                            {initiationPages.map((page) => (
                                <Pagination.Item
                                    key={'i-' + page}
                                    active={informationProcess.initiationPage === page}
                                    onClick={() => informationProcess.setInitiationPage(page)}
                                >
                                    {page}
                                </Pagination.Item>
                            ))}
                        </Pagination>
                    </Tab>
                    <Tab eventKey="draftDecisions" title="Proiecte de Decizii">
                        {informationProcess.draftDecisions.map((item, idx) => (
                            <Link
                                key={'draftDecisions' + idx}
                                className="d-flex flex-column justify-content-start py-3 text-decoration-none px-4 ps-xl-0"
                                to={DRAFT_DECISIONS_ROUTE + '/' + item.id}
                            >
                                <span className="fw-bold font-2 text-primary text-justify truncate">{item.title}</span>
                                <div className="d-flex justify-content-start py-2">
                                    <span className="border rounded border-primary px-2 text-primary">{item.date}</span>
                                </div>
                            </Link>
                        ))}
                        <Pagination className="d-flex justify-content-center align-items-center mt-5">
                            {draftDecisionsPages.map((page) => (
                                <Pagination.Item
                                    key={'df-' + page}
                                    active={informationProcess.draftDecisionsPage === page}
                                    onClick={() => informationProcess.setDraftDecisionsPage(page)}
                                >
                                    {page}
                                </Pagination.Item>
                            ))}
                        </Pagination>
                    </Tab>
                    <Tab eventKey="consultation" title="Consultare publică">
                        {informationProcess.consultation.map((item, idx) => (
                            <Link
                                key={'consultation' + idx}
                                className="d-flex flex-column justify-content-start py-3 text-decoration-none px-4 ps-xl-0"
                                to={PUBLIC_CONSULTATIONS_ROUTE + '/' + item.id}
                            >
                                <span className="fw-bold font-2 text-primary text-justify truncate">{item.title}</span>
                                <div className="d-flex justify-content-start py-2">
                                    <span className="border rounded border-primary px-2 text-primary">{item.date}</span>
                                </div>
                            </Link>
                        ))}
                        <Pagination className="d-flex justify-content-center align-items-center mt-5">
                            {consultationPages.map((page) => (
                                <Pagination.Item
                                    key={'c-' + page}
                                    active={informationProcess.consultationPage === page}
                                    onClick={() => informationProcess.setConsultationPage(page)}
                                >
                                    {page}
                                </Pagination.Item>
                            ))}
                        </Pagination>
                    </Tab>
                    <Tab eventKey="recommendations" title="Rapoarte">
                        {informationProcess.recommendations.map((item, idx) => (
                            <Link
                                key={'recommendations' + idx}
                                className="d-flex flex-column justify-content-start py-3 text-decoration-none px-4 ps-xl-0"
                                to={RECOMENDATIONS_ROUTE + '/' + item.id}
                            >
                                <span className="fw-bold font-2 text-primary text-justify truncate">{item.title}</span>
                                <div className="d-flex justify-content-start py-2">
                                    <span className="border rounded border-primary px-2 text-primary">{item.date}</span>
                                </div>
                            </Link>
                        ))}
                        <Pagination className="d-flex justify-content-center align-items-center mt-5">
                            {recommendationsPages.map((page) => (
                                <Pagination.Item
                                    key={'r-' + page}
                                    active={informationProcess.recommendationsPage === page}
                                    onClick={() => informationProcess.setRecommendationsPage(page)}
                                >
                                    {page}
                                </Pagination.Item>
                            ))}
                        </Pagination>
                    </Tab>
                </Tabs>
            </div>
        </div>
    );
});

export default InformationProcess;
