import { useRef } from 'react';

const usePrint = () => {
    const printRef = useRef(null);

    const handlePrint = () => {
        if (!printRef.current) return;

        const originalContent = document.body.innerHTML;
        document.body.innerHTML = printRef.current.outerHTML;
        window.print();

        window.onafterprint = () => {
            document.body.innerHTML = originalContent;
            window.location.reload();
        };
    };

    return { printRef, handlePrint };
};

export default usePrint;
