import { useEffect, useState, useContext } from 'react';
import {
    Form,
    Spinner,
    Accordion,
    Card,
    Image,
    useAccordionButton,
    Tabs,
    Tab,
    Dropdown,
    DropdownButton,
} from 'react-bootstrap';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { observer } from 'mobx-react-lite';
import { Context } from '../../index';
import { useNavigate } from 'react-router-dom';
import { SUCCESS_STORIES_ROUTE } from '../../utils/consts';
import {
    fetchChurchesMonasteries,
    fetchForKids,
    fetchHistoricBuildings,
    fetchHotels,
    fetchMonuments,
    fetchMuseums,
    fetchNotablePersonalities,
    fetchParks,
    fetchTouristActivities,
    fetchTouristRoutes,
    removeOneChurchesMonasteries,
    removeOneForKids,
    removeOneHistoricBuildings,
    removeOneHotels,
    removeOneMonuments,
    removeOneMuseums,
    removeOneNotablePersonalities,
    removeOneParks,
    removeOneTouristActivities,
    removeOneTouristRoutes,
    updateOneChurchesMonasteries,
    updateOneForKids,
    updateOneHistoricBuildings,
    updateOneHotels,
    updateOneMonuments,
    updateOneMuseums,
    updateOneNotablePersonalities,
    updateOneParks,
    updateOneTouristActivities,
    updateOneTouristRoutes,
} from '../../http/resourcesServicesAPI';
import AddVisitDistrictModal from './Modals/AddVisitDistrictModal';
import parse from 'html-react-parser';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const VisitDistrictAdmin = observer(() => {
    const { resourcesServices } = useContext(Context);
    const [show, setShow] = useState(false);

    const [loading, setLoading] = useState(true);
    const [selectedTab, setSelectedTab] = useState('Trasee Turistice');

    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [img, setImg] = useState([null]);
    const [author, setAuthor] = useState('');

    const navigate = useNavigate();

    const CustomToggle = ({ children, eventKey }) => {
        const decoratedOnClick = useAccordionButton(eventKey);

        return (
            <button
                className="btn btn-primary d-flex align-items-center mb-xl-1 mx-xl-0 mx-2"
                onClick={decoratedOnClick}
            >
                {children}
            </button>
        );
    };

    const modules = {
        toolbar: [
            [{ size: ['small', false, 'large', 'huge'] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }, { align: [] }],
            ['link', 'image'],
            [
                {
                    color: [
                        '#000000',
                        '#e60000',
                        '#ff9900',
                        '#ffff00',
                        '#008a00',
                        '#0066cc',
                        '#9933ff',
                        '#ffffff',
                        '#facccc',
                        '#ffebcc',
                        '#ffffcc',
                        '#cce8cc',
                        '#cce0f5',
                        '#ebd6ff',
                        '#bbbbbb',
                        '#f06666',
                        '#ffc266',
                        '#ffff66',
                        '#66b966',
                        '#66a3e0',
                        '#c285ff',
                        '#888888',
                        '#a10000',
                        '#b26b00',
                        '#b2b200',
                        '#006100',
                        '#0047b2',
                        '#6b24b2',
                        '#444444',
                        '#5c0000',
                        '#663d00',
                        '#666600',
                        '#003700',
                        '#002966',
                        '#3d1466',
                        'custom-color',
                    ],
                },
            ],
        ],
    };

    const formats = [
        'header',
        'height',
        'bold',
        'italic',
        'underline',
        'strike',
        'blockquote',
        'list',
        'color',
        'bullet',
        'indent',
        'link',
        'image',
        'align',
        'size',
    ];

    const editItem = (data) => {
        const id = data.id;
        const formData = new FormData();
        formData.append('title', title);
        formData.append('description', description);
        formData.append('img', img);
        formData.append('author', author);

        switch (selectedTab) {
            case 'Trasee Turistice':
                updateOneTouristRoutes(formData, id).then(() => window.location.reload());
                break;
            case 'Hoteluri':
                updateOneHotels(formData, id).then(() => window.location.reload());
                break;
            case 'Muzee':
                updateOneMuseums(formData, id).then(() => window.location.reload());
                break;
            case 'Parcuri':
                updateOneParks(formData, id).then(() => window.location.reload());
                break;
            case 'Activități Turistice':
                updateOneTouristActivities(formData, id).then(() => window.location.reload());
                break;
            case 'Clădiri istorice':
                updateOneHistoricBuildings(formData, id).then(() => window.location.reload());
                break;
            case 'Monumente':
                updateOneMonuments(formData, id).then(() => window.location.reload());
                break;
            case 'Personalități marcante':
                updateOneNotablePersonalities(formData, id).then(() => window.location.reload());
                break;
            case 'Pentru Copii':
                updateOneForKids(formData, id).then(() => window.location.reload());
                break;
            case 'Biserici și Mănăstiri':
                updateOneChurchesMonasteries(formData, id).then(() => window.location.reload());
                break;
        }
    };

    const removeItem = (item) => {
        const id = item.id;
        switch (selectedTab) {
            case 'Trasee Turistice':
                removeOneTouristRoutes(id).then(() => window.location.reload());
                break;
            case 'Hoteluri':
                removeOneHotels(id).then(() => window.location.reload());
                break;
            case 'Muzee':
                removeOneMuseums(id).then(() => window.location.reload());
                break;
            case 'Parcuri':
                removeOneParks(id).then(() => window.location.reload());
                break;
            case 'Activități Turistice':
                removeOneTouristActivities(id).then(() => window.location.reload());
                break;
            case 'Clădiri istorice':
                removeOneHistoricBuildings(id).then(() => window.location.reload());
                break;
            case 'Monumente':
                removeOneMonuments(id).then(() => window.location.reload());
                break;
            case 'Personalități marcante':
                removeOneNotablePersonalities(id).then(() => window.location.reload());
                break;
            case 'Pentru Copii':
                removeOneForKids(id).then(() => window.location.reload());
                break;
            case 'Biserici și Mănăstiri':
                removeOneChurchesMonasteries(id).then(() => window.location.reload());
                break;
        }
    };

    const selectFile = (e) => {
        setImg(e.target.files[0]);
    };

    useEffect(() => {
        fetchTouristRoutes().then((data) => {
            resourcesServices.setTouristRoutes(data);
        });
        fetchHotels().then((data) => {
            resourcesServices.setHotels(data);
        });
        fetchMuseums().then((data) => {
            resourcesServices.setMuseums(data);
        });
        fetchParks().then((data) => {
            resourcesServices.setParks(data);
        });
        fetchTouristActivities().then((data) => {
            resourcesServices.setTouristActivities(data);
        });
        fetchHistoricBuildings().then((data) => {
            resourcesServices.setHistoricBuildings(data);
        });
        fetchMonuments().then((data) => {
            resourcesServices.setMonuments(data);
        });
        fetchNotablePersonalities().then((data) => {
            resourcesServices.setNotablePersonalities(data);
        });
        fetchForKids().then((data) => {
            resourcesServices.setForKids(data);
        });
        fetchChurchesMonasteries()
            .then((data) => {
                resourcesServices.setChurchesMonasteries(data);
            })
            .finally(() => setLoading(false));
    }, []);

    if (loading) {
        return (
            <div className="d-flex align-items-center justify-content-center py-5">
                <Spinner animation={'grow'} />
            </div>
        );
    }

    return (
        <div className="d-flex flex-column bg-white r-corner-radius w-100">
            <div className="d-flex justify-content-between align-items-center font-2 fw-bold text-primary shadow-sm ps-3">
                <span>Vizitează r-ul Edineț</span>
                <button
                    className="btn btn-primary r-u-corner-radius"
                    style={{ height: 70, width: 70 }}
                    onClick={() => setShow(true)}
                >
                    <i className="bi bi-plus-circle icon-2" />
                </button>
            </div>
            <div className="d-flex flex-column px-2 pt-3">
                <Tabs
                    defaultActiveKey="Trasee Turistice"
                    id="uncontrolled-tab-example"
                    className="d-flex justify-content-center mb-3 text-primary fw-bold"
                    onSelect={(e) => setSelectedTab(e)}
                >
                    <Tab eventKey="Trasee Turistice" title="Trasee Turistice">
                        <div className="d-flex flex-column px-3">
                            <Accordion className="d-flex flex-column px-3">
                                {resourcesServices.touristRoutes.count > 0
                                    ? resourcesServices.touristRoutes.rows.map((item, idx) => (
                                          <div key={idx} className="shadow-sm border-0 my-2 px-4 rounded">
                                              <div className="d-flex flex-column">
                                                  <div className="d-flex flex-xl-row flex-column align-items-center justify-content-between p-3">
                                                      <div className="d-flex flex-column justify-content-center">
                                                          {item.img ? (
                                                              <Image
                                                                  style={{
                                                                      maxHeight: 200,
                                                                      aspectRatio: 1,
                                                                      height: 'auto',
                                                                      objectFit: 'contain',
                                                                  }}
                                                                  src={process.env.REACT_APP_API_URL + item.img}
                                                                  className="pe-xl-5 my-3"
                                                              />
                                                          ) : null}
                                                          <div className="d-flex flex-column  ps-xl-4">
                                                              <a
                                                                  role="button"
                                                                  onClick={() =>
                                                                      navigate(SUCCESS_STORIES_ROUTE + '/' + item.id)
                                                                  }
                                                                  className="text-decoration-none text-primary"
                                                              >
                                                                  <Card.Title className="text-primary truncate">
                                                                      {item.title}
                                                                  </Card.Title>
                                                              </a>
                                                              {item.description ? (
                                                                  <div className="truncate">
                                                                      {parse(item.description)}
                                                                  </div>
                                                              ) : null}
                                                          </div>
                                                      </div>
                                                      <div className="d-flex flex-xl-column flex-row justify-content-around py-xl-0 py-3">
                                                          <CustomToggle
                                                              eventKey={idx}
                                                              onClick={() => {
                                                                  useAccordionButton({ idx });
                                                              }}
                                                          >
                                                              <i className="bi bi-pencil-square icon-2 pe-2" />
                                                              <span>Edit</span>
                                                          </CustomToggle>
                                                          <button
                                                              className="btn btn-primary d-flex align-items-center mx-xl-0 mx-2"
                                                              onClick={() => removeItem(item)}
                                                          >
                                                              <i className="bi bi-x-circle icon-2 pe-2" />
                                                              <span>Remove</span>
                                                          </button>
                                                      </div>
                                                  </div>
                                              </div>
                                              <Accordion.Collapse eventKey={idx}>
                                                  <div className="d-flex flex-column justify-content-around px-4 py-4 border-top">
                                                      <div className="d-flex flex-column justify-content-around px-4 py-4">
                                                          <span className="text-primary font-2 py-2 ps-1">
                                                              Denumire
                                                          </span>
                                                          <Form.Control
                                                              aria-label="Title"
                                                              placeholder={item.title}
                                                              value={title}
                                                              onChange={(e) => {
                                                                  setTitle(e.target.value);
                                                              }}
                                                          />
                                                          <span className="text-primary  font-2 py-2 ps-1">
                                                              Descriere
                                                          </span>
                                                          <ReactQuill
                                                              theme="snow"
                                                              placeholder={item.description}
                                                              value={description}
                                                              modules={modules}
                                                              formats={formats}
                                                              onChange={setDescription}
                                                          />
                                                          <span className="text-primary font-2 py-2 ps-1">Autor</span>
                                                          <Form.Control
                                                              aria-label="Title"
                                                              placeholder={item.author}
                                                              value={author}
                                                              onChange={(e) => {
                                                                  setAuthor(e.target.value);
                                                              }}
                                                          />
                                                          <span className="text-primary font-2 py-2 ps-1">
                                                              Fotografie
                                                          </span>
                                                          <Form.Control
                                                              type={'file'}
                                                              onChange={selectFile}
                                                              aria-label="PageTitle"
                                                              aria-describedby="basic-addon"
                                                          />
                                                      </div>
                                                      <button
                                                          className="btn btn-primary d-flex align-items-center justify-content-center mx-xl-0 my-4"
                                                          onClick={() => editItem(item)}
                                                      >
                                                          <i className="bi bi-check icon-2 pe-2" />
                                                          <span>Confirm</span>
                                                      </button>
                                                  </div>
                                              </Accordion.Collapse>
                                          </div>
                                      ))
                                    : null}
                            </Accordion>
                        </div>
                    </Tab>
                    <Tab eventKey="Hoteluri" title="Hoteluri">
                        <div className="d-flex flex-column px-3">
                            <Accordion className="d-flex flex-column px-3">
                                {resourcesServices.hotels.count > 0
                                    ? resourcesServices.hotels.rows.map((item, idx) => (
                                          <div key={idx} className="shadow-sm border-0 my-2 px-4 rounded">
                                              <div className="d-flex flex-column">
                                                  <div className="d-flex flex-xl-row flex-column align-items-center justify-content-between p-3">
                                                      <div className="d-flex flex-column justify-content-center">
                                                          {item.img ? (
                                                              <Image
                                                                  style={{
                                                                      maxHeight: 200,
                                                                      aspectRatio: 1,
                                                                      height: 'auto',
                                                                      objectFit: 'contain',
                                                                  }}
                                                                  src={process.env.REACT_APP_API_URL + item.img}
                                                                  className="pe-xl-5 my-3"
                                                              />
                                                          ) : null}
                                                          <div className="d-flex flex-column  ps-xl-4">
                                                              <a
                                                                  role="button"
                                                                  onClick={() =>
                                                                      navigate(SUCCESS_STORIES_ROUTE + '/' + item.id)
                                                                  }
                                                                  className="text-decoration-none text-primary"
                                                              >
                                                                  <Card.Title className="text-primary truncate">
                                                                      {item.title}
                                                                  </Card.Title>
                                                              </a>
                                                              {item.description ? (
                                                                  <div className="truncate">
                                                                      {parse(item.description)}
                                                                  </div>
                                                              ) : null}
                                                          </div>
                                                      </div>
                                                      <div className="d-flex flex-xl-column flex-row justify-content-around py-xl-0 py-3">
                                                          <CustomToggle
                                                              eventKey={idx}
                                                              onClick={() => {
                                                                  useAccordionButton({ idx });
                                                              }}
                                                          >
                                                              <i className="bi bi-pencil-square icon-2 pe-2" />
                                                              <span>Edit</span>
                                                          </CustomToggle>
                                                          <button
                                                              className="btn btn-primary d-flex align-items-center mx-xl-0 mx-2"
                                                              onClick={() => removeItem(item)}
                                                          >
                                                              <i className="bi bi-x-circle icon-2 pe-2" />
                                                              <span>Remove</span>
                                                          </button>
                                                      </div>
                                                  </div>
                                              </div>
                                              <Accordion.Collapse eventKey={idx}>
                                                  <div className="d-flex flex-column justify-content-around px-4 py-4 border-top">
                                                      <div className="d-flex flex-column justify-content-around px-4 py-4">
                                                          <span className="text-primary font-2 py-2 ps-1">
                                                              Denumire
                                                          </span>
                                                          <Form.Control
                                                              aria-label="Title"
                                                              placeholder={item.title}
                                                              value={title}
                                                              onChange={(e) => {
                                                                  setTitle(e.target.value);
                                                              }}
                                                          />
                                                          <span className="text-primary  font-2 py-2 ps-1">
                                                              Descriere
                                                          </span>
                                                          <ReactQuill
                                                              theme="snow"
                                                              placeholder={item.description}
                                                              value={description}
                                                              modules={modules}
                                                              formats={formats}
                                                              onChange={setDescription}
                                                          />
                                                          <span className="text-primary font-2 py-2 ps-1">Autor</span>
                                                          <Form.Control
                                                              aria-label="Title"
                                                              placeholder={item.author}
                                                              value={author}
                                                              onChange={(e) => {
                                                                  setAuthor(e.target.value);
                                                              }}
                                                          />
                                                          <span className="text-primary font-2 py-2 ps-1">
                                                              Fotografie
                                                          </span>
                                                          <Form.Control
                                                              type={'file'}
                                                              onChange={selectFile}
                                                              aria-label="PageTitle"
                                                              aria-describedby="basic-addon"
                                                          />
                                                      </div>
                                                      <button
                                                          className="btn btn-primary d-flex align-items-center justify-content-center mx-xl-0 my-4"
                                                          onClick={() => editItem(item)}
                                                      >
                                                          <i className="bi bi-check icon-2 pe-2" />
                                                          <span>Confirm</span>
                                                      </button>
                                                  </div>
                                              </Accordion.Collapse>
                                          </div>
                                      ))
                                    : null}
                            </Accordion>
                        </div>
                    </Tab>
                    <Tab eventKey="Muzee" title="Muzee">
                        <div className="d-flex flex-column px-3">
                            <Accordion className="d-flex flex-column px-3">
                                {resourcesServices.museums.count > 0
                                    ? resourcesServices.museums.rows.map((item, idx) => (
                                          <div key={idx} className="shadow-sm border-0 my-2 px-4 rounded">
                                              <div className="d-flex flex-column">
                                                  <div className="d-flex flex-xl-row flex-column align-items-center justify-content-between p-3">
                                                      <div className="d-flex flex-column justify-content-center">
                                                          {item.img ? (
                                                              <Image
                                                                  style={{
                                                                      maxHeight: 200,
                                                                      aspectRatio: 1,
                                                                      height: 'auto',
                                                                      objectFit: 'contain',
                                                                  }}
                                                                  src={process.env.REACT_APP_API_URL + item.img}
                                                                  className="pe-xl-5 my-3"
                                                              />
                                                          ) : null}
                                                          <div className="d-flex flex-column  ps-xl-4">
                                                              <a
                                                                  role="button"
                                                                  onClick={() =>
                                                                      navigate(SUCCESS_STORIES_ROUTE + '/' + item.id)
                                                                  }
                                                                  className="text-decoration-none text-primary"
                                                              >
                                                                  <Card.Title className="text-primary truncate">
                                                                      {item.title}
                                                                  </Card.Title>
                                                              </a>
                                                              {item.description ? (
                                                                  <div className="truncate">
                                                                      {parse(item.description)}
                                                                  </div>
                                                              ) : null}
                                                          </div>
                                                      </div>
                                                      <div className="d-flex flex-xl-column flex-row justify-content-around py-xl-0 py-3">
                                                          <CustomToggle
                                                              eventKey={idx}
                                                              onClick={() => {
                                                                  useAccordionButton({ idx });
                                                              }}
                                                          >
                                                              <i className="bi bi-pencil-square icon-2 pe-2" />
                                                              <span>Edit</span>
                                                          </CustomToggle>
                                                          <button
                                                              className="btn btn-primary d-flex align-items-center mx-xl-0 mx-2"
                                                              onClick={() => removeItem(item)}
                                                          >
                                                              <i className="bi bi-x-circle icon-2 pe-2" />
                                                              <span>Remove</span>
                                                          </button>
                                                      </div>
                                                  </div>
                                              </div>
                                              <Accordion.Collapse eventKey={idx}>
                                                  <div className="d-flex flex-column justify-content-around px-4 py-4 border-top">
                                                      <div className="d-flex flex-column justify-content-around px-4 py-4">
                                                          <span className="text-primary font-2 py-2 ps-1">
                                                              Denumire
                                                          </span>
                                                          <Form.Control
                                                              aria-label="Title"
                                                              placeholder={item.title}
                                                              value={title}
                                                              onChange={(e) => {
                                                                  setTitle(e.target.value);
                                                              }}
                                                          />
                                                          <span className="text-primary  font-2 py-2 ps-1">
                                                              Descriere
                                                          </span>
                                                          <ReactQuill
                                                              theme="snow"
                                                              placeholder={item.description}
                                                              value={description}
                                                              modules={modules}
                                                              formats={formats}
                                                              onChange={setDescription}
                                                          />
                                                          <span className="text-primary font-2 py-2 ps-1">Autor</span>
                                                          <Form.Control
                                                              aria-label="Title"
                                                              placeholder={item.author}
                                                              value={author}
                                                              onChange={(e) => {
                                                                  setAuthor(e.target.value);
                                                              }}
                                                          />
                                                          <span className="text-primary font-2 py-2 ps-1">
                                                              Fotografie
                                                          </span>
                                                          <Form.Control
                                                              type={'file'}
                                                              onChange={selectFile}
                                                              aria-label="PageTitle"
                                                              aria-describedby="basic-addon"
                                                          />
                                                      </div>
                                                      <button
                                                          className="btn btn-primary d-flex align-items-center justify-content-center mx-xl-0 my-4"
                                                          onClick={() => editItem(item)}
                                                      >
                                                          <i className="bi bi-check icon-2 pe-2" />
                                                          <span>Confirm</span>
                                                      </button>
                                                  </div>
                                              </Accordion.Collapse>
                                          </div>
                                      ))
                                    : null}
                            </Accordion>
                        </div>
                    </Tab>
                    <Tab eventKey="Parcuri" title="Parcuri">
                        <div className="d-flex flex-column px-3">
                            <Accordion className="d-flex flex-column px-3">
                                {resourcesServices.parks.count > 0
                                    ? resourcesServices.parks.rows.map((item, idx) => (
                                          <div key={idx} className="shadow-sm border-0 my-2 px-4 rounded">
                                              <div className="d-flex flex-column">
                                                  <div className="d-flex flex-xl-row flex-column align-items-center justify-content-between p-3">
                                                      <div className="d-flex flex-column justify-content-center">
                                                          {item.img ? (
                                                              <Image
                                                                  style={{
                                                                      maxHeight: 200,
                                                                      aspectRatio: 1,
                                                                      height: 'auto',
                                                                      objectFit: 'contain',
                                                                  }}
                                                                  src={process.env.REACT_APP_API_URL + item.img}
                                                                  className="pe-xl-5 my-3"
                                                              />
                                                          ) : null}
                                                          <div className="d-flex flex-column  ps-xl-4">
                                                              <a
                                                                  role="button"
                                                                  onClick={() =>
                                                                      navigate(SUCCESS_STORIES_ROUTE + '/' + item.id)
                                                                  }
                                                                  className="text-decoration-none text-primary"
                                                              >
                                                                  <Card.Title className="text-primary truncate">
                                                                      {item.title}
                                                                  </Card.Title>
                                                              </a>
                                                              {item.description ? (
                                                                  <div className="truncate">
                                                                      {parse(item.description)}
                                                                  </div>
                                                              ) : null}
                                                          </div>
                                                      </div>
                                                      <div className="d-flex flex-xl-column flex-row justify-content-around py-xl-0 py-3">
                                                          <CustomToggle
                                                              eventKey={idx}
                                                              onClick={() => {
                                                                  useAccordionButton({ idx });
                                                              }}
                                                          >
                                                              <i className="bi bi-pencil-square icon-2 pe-2" />
                                                              <span>Edit</span>
                                                          </CustomToggle>
                                                          <button
                                                              className="btn btn-primary d-flex align-items-center mx-xl-0 mx-2"
                                                              onClick={() => removeItem(item)}
                                                          >
                                                              <i className="bi bi-x-circle icon-2 pe-2" />
                                                              <span>Remove</span>
                                                          </button>
                                                      </div>
                                                  </div>
                                              </div>
                                              <Accordion.Collapse eventKey={idx}>
                                                  <div className="d-flex flex-column justify-content-around px-4 py-4 border-top">
                                                      <div className="d-flex flex-column justify-content-around px-4 py-4">
                                                          <span className="text-primary font-2 py-2 ps-1">
                                                              Denumire
                                                          </span>
                                                          <Form.Control
                                                              aria-label="Title"
                                                              placeholder={item.title}
                                                              value={title}
                                                              onChange={(e) => {
                                                                  setTitle(e.target.value);
                                                              }}
                                                          />
                                                          <span className="text-primary  font-2 py-2 ps-1">
                                                              Descriere
                                                          </span>
                                                          <ReactQuill
                                                              theme="snow"
                                                              placeholder={item.description}
                                                              value={description}
                                                              modules={modules}
                                                              formats={formats}
                                                              onChange={setDescription}
                                                          />
                                                          <span className="text-primary font-2 py-2 ps-1">Autor</span>
                                                          <Form.Control
                                                              aria-label="Title"
                                                              placeholder={item.author}
                                                              value={author}
                                                              onChange={(e) => {
                                                                  setAuthor(e.target.value);
                                                              }}
                                                          />
                                                          <span className="text-primary font-2 py-2 ps-1">
                                                              Fotografie
                                                          </span>
                                                          <Form.Control
                                                              type={'file'}
                                                              onChange={selectFile}
                                                              aria-label="PageTitle"
                                                              aria-describedby="basic-addon"
                                                          />
                                                      </div>
                                                      <button
                                                          className="btn btn-primary d-flex align-items-center justify-content-center mx-xl-0 my-4"
                                                          onClick={() => editItem(item)}
                                                      >
                                                          <i className="bi bi-check icon-2 pe-2" />
                                                          <span>Confirm</span>
                                                      </button>
                                                  </div>
                                              </Accordion.Collapse>
                                          </div>
                                      ))
                                    : null}
                            </Accordion>
                        </div>
                    </Tab>
                    <Tab eventKey="Activități Turistice" title="Activități Turistice">
                        <div className="d-flex flex-column px-3">
                            <Accordion className="d-flex flex-column px-3">
                                {resourcesServices.touristActivities.count > 0
                                    ? resourcesServices.touristActivities.rows.map((item, idx) => (
                                          <div key={idx} className="shadow-sm border-0 my-2 px-4 rounded">
                                              <div className="d-flex flex-column">
                                                  <div className="d-flex flex-xl-row flex-column align-items-center justify-content-between p-3">
                                                      <div className="d-flex flex-column justify-content-center">
                                                          {item.img ? (
                                                              <Image
                                                                  style={{
                                                                      maxHeight: 200,
                                                                      aspectRatio: 1,
                                                                      height: 'auto',
                                                                      objectFit: 'contain',
                                                                  }}
                                                                  src={process.env.REACT_APP_API_URL + item.img}
                                                                  className="pe-xl-5 my-3"
                                                              />
                                                          ) : null}
                                                          <div className="d-flex flex-column  ps-xl-4">
                                                              <a
                                                                  role="button"
                                                                  onClick={() =>
                                                                      navigate(SUCCESS_STORIES_ROUTE + '/' + item.id)
                                                                  }
                                                                  className="text-decoration-none text-primary"
                                                              >
                                                                  <Card.Title className="text-primary truncate">
                                                                      {item.title}
                                                                  </Card.Title>
                                                              </a>
                                                              {item.description ? (
                                                                  <div className="truncate">
                                                                      {parse(item.description)}
                                                                  </div>
                                                              ) : null}
                                                          </div>
                                                      </div>
                                                      <div className="d-flex flex-xl-column flex-row justify-content-around py-xl-0 py-3">
                                                          <CustomToggle
                                                              eventKey={idx}
                                                              onClick={() => {
                                                                  useAccordionButton({ idx });
                                                              }}
                                                          >
                                                              <i className="bi bi-pencil-square icon-2 pe-2" />
                                                              <span>Edit</span>
                                                          </CustomToggle>
                                                          <button
                                                              className="btn btn-primary d-flex align-items-center mx-xl-0 mx-2"
                                                              onClick={() => removeItem(item)}
                                                          >
                                                              <i className="bi bi-x-circle icon-2 pe-2" />
                                                              <span>Remove</span>
                                                          </button>
                                                      </div>
                                                  </div>
                                              </div>
                                              <Accordion.Collapse eventKey={idx}>
                                                  <div className="d-flex flex-column justify-content-around px-4 py-4 border-top">
                                                      <div className="d-flex flex-column justify-content-around px-4 py-4">
                                                          <span className="text-primary font-2 py-2 ps-1">
                                                              Denumire
                                                          </span>
                                                          <Form.Control
                                                              aria-label="Title"
                                                              placeholder={item.title}
                                                              value={title}
                                                              onChange={(e) => {
                                                                  setTitle(e.target.value);
                                                              }}
                                                          />
                                                          <span className="text-primary  font-2 py-2 ps-1">
                                                              Descriere
                                                          </span>
                                                          <ReactQuill
                                                              theme="snow"
                                                              placeholder={item.description}
                                                              value={description}
                                                              modules={modules}
                                                              formats={formats}
                                                              onChange={setDescription}
                                                          />
                                                          <span className="text-primary font-2 py-2 ps-1">Autor</span>
                                                          <Form.Control
                                                              aria-label="Title"
                                                              placeholder={item.author}
                                                              value={author}
                                                              onChange={(e) => {
                                                                  setAuthor(e.target.value);
                                                              }}
                                                          />
                                                          <span className="text-primary font-2 py-2 ps-1">
                                                              Fotografie
                                                          </span>
                                                          <Form.Control
                                                              type={'file'}
                                                              onChange={selectFile}
                                                              aria-label="PageTitle"
                                                              aria-describedby="basic-addon"
                                                          />
                                                      </div>
                                                      <button
                                                          className="btn btn-primary d-flex align-items-center justify-content-center mx-xl-0 my-4"
                                                          onClick={() => editItem(item)}
                                                      >
                                                          <i className="bi bi-check icon-2 pe-2" />
                                                          <span>Confirm</span>
                                                      </button>
                                                  </div>
                                              </Accordion.Collapse>
                                          </div>
                                      ))
                                    : null}
                            </Accordion>
                        </div>
                    </Tab>
                    <Tab eventKey="Clădiri istorice" title="Clădiri istorice">
                        <div className="d-flex flex-column px-3">
                            <Accordion className="d-flex flex-column px-3">
                                {resourcesServices.historicBuildings.count > 0
                                    ? resourcesServices.historicBuildings.rows.map((item, idx) => (
                                          <div key={idx} className="shadow-sm border-0 my-2 px-4 rounded">
                                              <div className="d-flex flex-column">
                                                  <div className="d-flex flex-xl-row flex-column align-items-center justify-content-between p-3">
                                                      <div className="d-flex flex-column justify-content-center">
                                                          {item.img ? (
                                                              <Image
                                                                  style={{
                                                                      maxHeight: 200,
                                                                      aspectRatio: 1,
                                                                      height: 'auto',
                                                                      objectFit: 'contain',
                                                                  }}
                                                                  src={process.env.REACT_APP_API_URL + item.img}
                                                                  className="pe-xl-5 my-3"
                                                              />
                                                          ) : null}
                                                          <div className="d-flex flex-column  ps-xl-4">
                                                              <a
                                                                  role="button"
                                                                  onClick={() =>
                                                                      navigate(SUCCESS_STORIES_ROUTE + '/' + item.id)
                                                                  }
                                                                  className="text-decoration-none text-primary"
                                                              >
                                                                  <Card.Title className="text-primary truncate">
                                                                      {item.title}
                                                                  </Card.Title>
                                                              </a>
                                                              {item.description ? (
                                                                  <div className="truncate">
                                                                      {parse(item.description)}
                                                                  </div>
                                                              ) : null}
                                                          </div>
                                                      </div>
                                                      <div className="d-flex flex-xl-column flex-row justify-content-around py-xl-0 py-3">
                                                          <CustomToggle
                                                              eventKey={idx}
                                                              onClick={() => {
                                                                  useAccordionButton({ idx });
                                                              }}
                                                          >
                                                              <i className="bi bi-pencil-square icon-2 pe-2" />
                                                              <span>Edit</span>
                                                          </CustomToggle>
                                                          <button
                                                              className="btn btn-primary d-flex align-items-center mx-xl-0 mx-2"
                                                              onClick={() => removeItem(item)}
                                                          >
                                                              <i className="bi bi-x-circle icon-2 pe-2" />
                                                              <span>Remove</span>
                                                          </button>
                                                      </div>
                                                  </div>
                                              </div>
                                              <Accordion.Collapse eventKey={idx}>
                                                  <div className="d-flex flex-column justify-content-around px-4 py-4 border-top">
                                                      <div className="d-flex flex-column justify-content-around px-4 py-4">
                                                          <span className="text-primary font-2 py-2 ps-1">
                                                              Denumire
                                                          </span>
                                                          <Form.Control
                                                              aria-label="Title"
                                                              placeholder={item.title}
                                                              value={title}
                                                              onChange={(e) => {
                                                                  setTitle(e.target.value);
                                                              }}
                                                          />
                                                          <span className="text-primary  font-2 py-2 ps-1">
                                                              Descriere
                                                          </span>
                                                          <ReactQuill
                                                              theme="snow"
                                                              placeholder={item.description}
                                                              value={description}
                                                              modules={modules}
                                                              formats={formats}
                                                              onChange={setDescription}
                                                          />
                                                          <span className="text-primary font-2 py-2 ps-1">Autor</span>
                                                          <Form.Control
                                                              aria-label="Title"
                                                              placeholder={item.author}
                                                              value={author}
                                                              onChange={(e) => {
                                                                  setAuthor(e.target.value);
                                                              }}
                                                          />
                                                          <span className="text-primary font-2 py-2 ps-1">
                                                              Fotografie
                                                          </span>
                                                          <Form.Control
                                                              type={'file'}
                                                              onChange={selectFile}
                                                              aria-label="PageTitle"
                                                              aria-describedby="basic-addon"
                                                          />
                                                      </div>
                                                      <button
                                                          className="btn btn-primary d-flex align-items-center justify-content-center mx-xl-0 my-4"
                                                          onClick={() => editItem(item)}
                                                      >
                                                          <i className="bi bi-check icon-2 pe-2" />
                                                          <span>Confirm</span>
                                                      </button>
                                                  </div>
                                              </Accordion.Collapse>
                                          </div>
                                      ))
                                    : null}
                            </Accordion>
                        </div>
                    </Tab>
                    <Tab eventKey="Monumente" title="Monumente">
                        <div className="d-flex flex-column px-3">
                            <Accordion className="d-flex flex-column px-3">
                                {resourcesServices.monuments.count > 0
                                    ? resourcesServices.monuments.rows.map((item, idx) => (
                                          <div key={idx} className="shadow-sm border-0 my-2 px-4 rounded">
                                              <div className="d-flex flex-column">
                                                  <div className="d-flex flex-xl-row flex-column align-items-center justify-content-between p-3">
                                                      <div className="d-flex flex-column justify-content-center">
                                                          {item.img ? (
                                                              <Image
                                                                  style={{
                                                                      maxHeight: 200,
                                                                      aspectRatio: 1,
                                                                      height: 'auto',
                                                                      objectFit: 'contain',
                                                                  }}
                                                                  src={process.env.REACT_APP_API_URL + item.img}
                                                                  className="pe-xl-5 my-3"
                                                              />
                                                          ) : null}
                                                          <div className="d-flex flex-column  ps-xl-4">
                                                              <a
                                                                  role="button"
                                                                  onClick={() =>
                                                                      navigate(SUCCESS_STORIES_ROUTE + '/' + item.id)
                                                                  }
                                                                  className="text-decoration-none text-primary"
                                                              >
                                                                  <Card.Title className="text-primary truncate">
                                                                      {item.title}
                                                                  </Card.Title>
                                                              </a>
                                                              {item.description ? (
                                                                  <div className="truncate">
                                                                      {parse(item.description)}
                                                                  </div>
                                                              ) : null}
                                                          </div>
                                                      </div>
                                                      <div className="d-flex flex-xl-column flex-row justify-content-around py-xl-0 py-3">
                                                          <CustomToggle
                                                              eventKey={idx}
                                                              onClick={() => {
                                                                  useAccordionButton({ idx });
                                                              }}
                                                          >
                                                              <i className="bi bi-pencil-square icon-2 pe-2" />
                                                              <span>Edit</span>
                                                          </CustomToggle>
                                                          <button
                                                              className="btn btn-primary d-flex align-items-center mx-xl-0 mx-2"
                                                              onClick={() => removeItem(item)}
                                                          >
                                                              <i className="bi bi-x-circle icon-2 pe-2" />
                                                              <span>Remove</span>
                                                          </button>
                                                      </div>
                                                  </div>
                                              </div>
                                              <Accordion.Collapse eventKey={idx}>
                                                  <div className="d-flex flex-column justify-content-around px-4 py-4 border-top">
                                                      <div className="d-flex flex-column justify-content-around px-4 py-4">
                                                          <span className="text-primary font-2 py-2 ps-1">
                                                              Denumire
                                                          </span>
                                                          <Form.Control
                                                              aria-label="Title"
                                                              placeholder={item.title}
                                                              value={title}
                                                              onChange={(e) => {
                                                                  setTitle(e.target.value);
                                                              }}
                                                          />
                                                          <span className="text-primary  font-2 py-2 ps-1">
                                                              Descriere
                                                          </span>
                                                          <ReactQuill
                                                              theme="snow"
                                                              placeholder={item.description}
                                                              value={description}
                                                              modules={modules}
                                                              formats={formats}
                                                              onChange={setDescription}
                                                          />
                                                          <span className="text-primary font-2 py-2 ps-1">Autor</span>
                                                          <Form.Control
                                                              aria-label="Title"
                                                              placeholder={item.author}
                                                              value={author}
                                                              onChange={(e) => {
                                                                  setAuthor(e.target.value);
                                                              }}
                                                          />
                                                          <span className="text-primary font-2 py-2 ps-1">
                                                              Fotografie
                                                          </span>
                                                          <Form.Control
                                                              type={'file'}
                                                              onChange={selectFile}
                                                              aria-label="PageTitle"
                                                              aria-describedby="basic-addon"
                                                          />
                                                      </div>
                                                      <button
                                                          className="btn btn-primary d-flex align-items-center justify-content-center mx-xl-0 my-4"
                                                          onClick={() => editItem(item)}
                                                      >
                                                          <i className="bi bi-check icon-2 pe-2" />
                                                          <span>Confirm</span>
                                                      </button>
                                                  </div>
                                              </Accordion.Collapse>
                                          </div>
                                      ))
                                    : null}
                            </Accordion>
                        </div>
                    </Tab>
                    <Tab eventKey="Personalități marcante" title="Personalități marcante">
                        <div className="d-flex flex-column px-3">
                            <Accordion className="d-flex flex-column px-3">
                                {resourcesServices.notablePersonalities.count > 0
                                    ? resourcesServices.notablePersonalities.rows.map((item, idx) => (
                                          <div key={idx} className="shadow-sm border-0 my-2 px-4 rounded">
                                              <div className="d-flex flex-column">
                                                  <div className="d-flex flex-xl-row flex-column align-items-center justify-content-between p-3">
                                                      <div className="d-flex flex-column justify-content-center">
                                                          {item.img ? (
                                                              <Image
                                                                  style={{
                                                                      maxHeight: 200,
                                                                      aspectRatio: 1,
                                                                      height: 'auto',
                                                                      objectFit: 'contain',
                                                                  }}
                                                                  src={process.env.REACT_APP_API_URL + item.img}
                                                                  className="pe-xl-5 my-3"
                                                              />
                                                          ) : null}
                                                          <div className="d-flex flex-column  ps-xl-4">
                                                              <a
                                                                  role="button"
                                                                  onClick={() =>
                                                                      navigate(SUCCESS_STORIES_ROUTE + '/' + item.id)
                                                                  }
                                                                  className="text-decoration-none text-primary"
                                                              >
                                                                  <Card.Title className="text-primary truncate">
                                                                      {item.title}
                                                                  </Card.Title>
                                                              </a>
                                                              {item.description ? (
                                                                  <div className="truncate">
                                                                      {parse(item.description)}
                                                                  </div>
                                                              ) : null}
                                                          </div>
                                                      </div>
                                                      <div className="d-flex flex-xl-column flex-row justify-content-around py-xl-0 py-3">
                                                          <CustomToggle
                                                              eventKey={idx}
                                                              onClick={() => {
                                                                  useAccordionButton({ idx });
                                                              }}
                                                          >
                                                              <i className="bi bi-pencil-square icon-2 pe-2" />
                                                              <span>Edit</span>
                                                          </CustomToggle>
                                                          <button
                                                              className="btn btn-primary d-flex align-items-center mx-xl-0 mx-2"
                                                              onClick={() => removeItem(item)}
                                                          >
                                                              <i className="bi bi-x-circle icon-2 pe-2" />
                                                              <span>Remove</span>
                                                          </button>
                                                      </div>
                                                  </div>
                                              </div>
                                              <Accordion.Collapse eventKey={idx}>
                                                  <div className="d-flex flex-column justify-content-around px-4 py-4 border-top">
                                                      <div className="d-flex flex-column justify-content-around px-4 py-4">
                                                          <span className="text-primary font-2 py-2 ps-1">
                                                              Denumire
                                                          </span>
                                                          <Form.Control
                                                              aria-label="Title"
                                                              placeholder={item.title}
                                                              value={title}
                                                              onChange={(e) => {
                                                                  setTitle(e.target.value);
                                                              }}
                                                          />
                                                          <span className="text-primary  font-2 py-2 ps-1">
                                                              Descriere
                                                          </span>
                                                          <ReactQuill
                                                              theme="snow"
                                                              placeholder={item.description}
                                                              value={description}
                                                              modules={modules}
                                                              formats={formats}
                                                              onChange={setDescription}
                                                          />
                                                          <span className="text-primary font-2 py-2 ps-1">Autor</span>
                                                          <Form.Control
                                                              aria-label="Title"
                                                              placeholder={item.author}
                                                              value={author}
                                                              onChange={(e) => {
                                                                  setAuthor(e.target.value);
                                                              }}
                                                          />
                                                          <span className="text-primary font-2 py-2 ps-1">
                                                              Fotografie
                                                          </span>
                                                          <Form.Control
                                                              type={'file'}
                                                              onChange={selectFile}
                                                              aria-label="PageTitle"
                                                              aria-describedby="basic-addon"
                                                          />
                                                      </div>
                                                      <button
                                                          className="btn btn-primary d-flex align-items-center justify-content-center mx-xl-0 my-4"
                                                          onClick={() => editItem(item)}
                                                      >
                                                          <i className="bi bi-check icon-2 pe-2" />
                                                          <span>Confirm</span>
                                                      </button>
                                                  </div>
                                              </Accordion.Collapse>
                                          </div>
                                      ))
                                    : null}
                            </Accordion>
                        </div>
                    </Tab>
                    <Tab eventKey="Pentru Copii" title="Pentru Copii">
                        <div className="d-flex flex-column px-3">
                            <Accordion className="d-flex flex-column px-3">
                                {resourcesServices.forKids.count > 0
                                    ? resourcesServices.forKids.rows.map((item, idx) => (
                                          <div key={idx} className="shadow-sm border-0 my-2 px-4 rounded">
                                              <div className="d-flex flex-column">
                                                  <div className="d-flex flex-xl-row flex-column align-items-center justify-content-between p-3">
                                                      <div className="d-flex flex-column justify-content-center">
                                                          {item.img ? (
                                                              <Image
                                                                  style={{
                                                                      maxHeight: 200,
                                                                      aspectRatio: 1,
                                                                      height: 'auto',
                                                                      objectFit: 'contain',
                                                                  }}
                                                                  src={process.env.REACT_APP_API_URL + item.img}
                                                                  className="pe-xl-5 my-3"
                                                              />
                                                          ) : null}
                                                          <div className="d-flex flex-column  ps-xl-4">
                                                              <a
                                                                  role="button"
                                                                  onClick={() =>
                                                                      navigate(SUCCESS_STORIES_ROUTE + '/' + item.id)
                                                                  }
                                                                  className="text-decoration-none text-primary"
                                                              >
                                                                  <Card.Title className="text-primary truncate">
                                                                      {item.title}
                                                                  </Card.Title>
                                                              </a>
                                                              {item.description ? (
                                                                  <div className="truncate">
                                                                      {parse(item.description)}
                                                                  </div>
                                                              ) : null}
                                                          </div>
                                                      </div>
                                                      <div className="d-flex flex-xl-column flex-row justify-content-around py-xl-0 py-3">
                                                          <CustomToggle
                                                              eventKey={idx}
                                                              onClick={() => {
                                                                  useAccordionButton({ idx });
                                                              }}
                                                          >
                                                              <i className="bi bi-pencil-square icon-2 pe-2" />
                                                              <span>Edit</span>
                                                          </CustomToggle>
                                                          <button
                                                              className="btn btn-primary d-flex align-items-center mx-xl-0 mx-2"
                                                              onClick={() => removeItem(item)}
                                                          >
                                                              <i className="bi bi-x-circle icon-2 pe-2" />
                                                              <span>Remove</span>
                                                          </button>
                                                      </div>
                                                  </div>
                                              </div>
                                              <Accordion.Collapse eventKey={idx}>
                                                  <div className="d-flex flex-column justify-content-around px-4 py-4 border-top">
                                                      <div className="d-flex flex-column justify-content-around px-4 py-4">
                                                          <span className="text-primary font-2 py-2 ps-1">
                                                              Denumire
                                                          </span>
                                                          <Form.Control
                                                              aria-label="Title"
                                                              placeholder={item.title}
                                                              value={title}
                                                              onChange={(e) => {
                                                                  setTitle(e.target.value);
                                                              }}
                                                          />
                                                          <span className="text-primary  font-2 py-2 ps-1">
                                                              Descriere
                                                          </span>
                                                          <ReactQuill
                                                              theme="snow"
                                                              placeholder={item.description}
                                                              value={description}
                                                              modules={modules}
                                                              formats={formats}
                                                              onChange={setDescription}
                                                          />
                                                          <span className="text-primary font-2 py-2 ps-1">Autor</span>
                                                          <Form.Control
                                                              aria-label="Title"
                                                              placeholder={item.author}
                                                              value={author}
                                                              onChange={(e) => {
                                                                  setAuthor(e.target.value);
                                                              }}
                                                          />
                                                          <span className="text-primary font-2 py-2 ps-1">
                                                              Fotografie
                                                          </span>
                                                          <Form.Control
                                                              type={'file'}
                                                              onChange={selectFile}
                                                              aria-label="PageTitle"
                                                              aria-describedby="basic-addon"
                                                          />
                                                      </div>
                                                      <button
                                                          className="btn btn-primary d-flex align-items-center justify-content-center mx-xl-0 my-4"
                                                          onClick={() => editItem(item)}
                                                      >
                                                          <i className="bi bi-check icon-2 pe-2" />
                                                          <span>Confirm</span>
                                                      </button>
                                                  </div>
                                              </Accordion.Collapse>
                                          </div>
                                      ))
                                    : null}
                            </Accordion>
                        </div>
                    </Tab>
                    <Tab eventKey="Biserici și Mănăstiri" title="Biserici și Mănăstiri">
                        <div className="d-flex flex-column px-3">
                            <Accordion className="d-flex flex-column px-3">
                                {resourcesServices.churchesMonasteries.count > 0
                                    ? resourcesServices.churchesMonasteries.rows.map((item, idx) => (
                                          <div key={idx} className="shadow-sm border-0 my-2 px-4 rounded">
                                              <div className="d-flex flex-column">
                                                  <div className="d-flex flex-xl-row flex-column align-items-center justify-content-between p-3">
                                                      <div className="d-flex flex-column justify-content-center">
                                                          {item.img ? (
                                                              <Image
                                                                  style={{
                                                                      maxHeight: 200,
                                                                      aspectRatio: 1,
                                                                      height: 'auto',
                                                                      objectFit: 'contain',
                                                                  }}
                                                                  src={process.env.REACT_APP_API_URL + item.img}
                                                                  className="pe-xl-5 my-3"
                                                              />
                                                          ) : null}
                                                          <div className="d-flex flex-column  ps-xl-4">
                                                              <a
                                                                  role="button"
                                                                  onClick={() =>
                                                                      navigate(SUCCESS_STORIES_ROUTE + '/' + item.id)
                                                                  }
                                                                  className="text-decoration-none text-primary"
                                                              >
                                                                  <Card.Title className="text-primary truncate">
                                                                      {item.title}
                                                                  </Card.Title>
                                                              </a>
                                                              {item.description ? (
                                                                  <div className="truncate">
                                                                      {parse(item.description)}
                                                                  </div>
                                                              ) : null}
                                                          </div>
                                                      </div>
                                                      <div className="d-flex flex-xl-column flex-row justify-content-around py-xl-0 py-3">
                                                          <CustomToggle
                                                              eventKey={idx}
                                                              onClick={() => {
                                                                  useAccordionButton({ idx });
                                                              }}
                                                          >
                                                              <i className="bi bi-pencil-square icon-2 pe-2" />
                                                              <span>Edit</span>
                                                          </CustomToggle>
                                                          <button
                                                              className="btn btn-primary d-flex align-items-center mx-xl-0 mx-2"
                                                              onClick={() => removeItem(item)}
                                                          >
                                                              <i className="bi bi-x-circle icon-2 pe-2" />
                                                              <span>Remove</span>
                                                          </button>
                                                      </div>
                                                  </div>
                                              </div>
                                              <Accordion.Collapse eventKey={idx}>
                                                  <div className="d-flex flex-column justify-content-around px-4 py-4 border-top">
                                                      <div className="d-flex flex-column justify-content-around px-4 py-4">
                                                          <span className="text-primary font-2 py-2 ps-1">
                                                              Denumire
                                                          </span>
                                                          <Form.Control
                                                              aria-label="Title"
                                                              placeholder={item.title}
                                                              value={title}
                                                              onChange={(e) => {
                                                                  setTitle(e.target.value);
                                                              }}
                                                          />
                                                          <span className="text-primary  font-2 py-2 ps-1">
                                                              Descriere
                                                          </span>
                                                          <ReactQuill
                                                              theme="snow"
                                                              placeholder={item.description}
                                                              value={description}
                                                              modules={modules}
                                                              formats={formats}
                                                              onChange={setDescription}
                                                          />
                                                          <span className="text-primary font-2 py-2 ps-1">Autor</span>
                                                          <Form.Control
                                                              aria-label="Title"
                                                              placeholder={item.author}
                                                              value={author}
                                                              onChange={(e) => {
                                                                  setAuthor(e.target.value);
                                                              }}
                                                          />
                                                          <span className="text-primary font-2 py-2 ps-1">
                                                              Fotografie
                                                          </span>
                                                          <Form.Control
                                                              type={'file'}
                                                              onChange={selectFile}
                                                              aria-label="PageTitle"
                                                              aria-describedby="basic-addon"
                                                          />
                                                      </div>
                                                      <button
                                                          className="btn btn-primary d-flex align-items-center justify-content-center mx-xl-0 my-4"
                                                          onClick={() => editItem(item)}
                                                      >
                                                          <i className="bi bi-check icon-2 pe-2" />
                                                          <span>Confirm</span>
                                                      </button>
                                                  </div>
                                              </Accordion.Collapse>
                                          </div>
                                      ))
                                    : null}
                            </Accordion>
                        </div>
                    </Tab>
                </Tabs>
            </div>
            <AddVisitDistrictModal show={show} onHide={() => setShow(false)} selectedTab={selectedTab} />
        </div>
    );
});

export default VisitDistrictAdmin;
