import React, { useContext } from 'react';
import { Routes, Route } from 'react-router-dom';
import { authRoutes, publicRoutes } from '../routes';
import Home from '../pages/Home';
import { Context } from '..';
import { observer } from 'mobx-react-lite';
import useScrollRestoration from './UI/useScrollRestoration';

const AppRouter = observer(() => {
    const { user } = useContext(Context);
    useScrollRestoration();

    return (
        <Routes>
            {user.isAuth &&
                authRoutes.map(({ path, Component }) => <Route key={path} path={path} element={Component} exact />)}
            {publicRoutes.map(({ path, Component }) => (
                <Route key={path} path={path} element={Component} exact />
            ))}
            <Route path="*" element={<Home />} />
        </Routes>
    );
});

export default AppRouter;
