import React, { useEffect, useState } from 'react';
import { fetchOneTouristRoutes } from '../../../http/resourcesServicesAPI';
import PostPageLayout from '../../../components/layouts/PostPageLayout';
import { useParams } from 'react-router-dom';

const TouristRoutesPage = () => {
    const { id } = useParams();
    const [data, setData] = useState([]);
    useEffect(() => {
        fetchOneTouristRoutes(id).then((data) => {
            setData(data);
        });
    }, []);

    return <PostPageLayout props={data} />;
};

export default TouristRoutesPage;
